import React from 'react';
import ReactDOM from 'react-dom';
import ChooseMajDurModal from './ChooseMajDurModal';

const ChooseMajDurPortal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <ChooseMajDurModal onClose={onClose}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default ChooseMajDurPortal;