import React, { createContext, useContext, useEffect, useState } from "react";

const MajorsContext = createContext();

export const MajorsProvider = ({ children }) => {
  const [allMajors, setAllMajors] = useState(() => {
    const storedMajors = localStorage.getItem("allMajors");
    return storedMajors ? JSON.parse(storedMajors) : [];
  });

  const [allEntries, setAllEntries] = useState(() => {
    const storedEntries = localStorage.getItem("allEntries");
    return storedEntries ? JSON.parse(storedEntries) : [];
  });

  const [selectedMajors, setSelectedMajors] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);

  const removeMajor = (id) => {
    const updatedSelectedMajors = selectedMajors?.filter(
      (major) => major.id !== id
    );
    setSelectedMajors(updatedSelectedMajors);

    setAllMajors((prevMajors) =>
      prevMajors.map((major) =>
        major.id === id ? { ...major, selected: false } : major
      )
    );
  };

  const addSelectedMajors = () => {
    const selectedItems = allMajors?.filter((major) => major.selected);
    setSelectedMajors(selectedItems);
  };

  const addSelectedEntries = () => {
    const selectedItems = allEntries?.filter((entry) => entry.selected);
    setSelectedEntries(selectedItems);
  };

  const updateMajorSelection = (id, selected) => {
    setAllMajors((prevMajors) => {
      return prevMajors.map((major) =>
        major.id === id ? { ...major, selected } : major
      );
    });
  };

  const updateEntrySelection = (id, selected) => {
    setAllEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, selected } : entry
      )
    );
  };

  useEffect(() => {
    const selectedItems = allEntries?.filter((entry) => entry.selected);
    setSelectedEntries(selectedItems);
    localStorage.setItem("allEntries", JSON.stringify(allEntries));
  }, [allEntries]);

  useEffect(() => {
    const selectedItems = allMajors?.filter((major) => major.selected);
    setSelectedMajors(selectedItems);
    localStorage.setItem("allMajors", JSON.stringify(allMajors));
  }, [allMajors]);

  return (
    <MajorsContext.Provider
      value={{
        allMajors,
        allEntries,
        setAllMajors,
        setAllEntries,
        selectedMajors,
        setSelectedMajors,
        removeMajor,
        updateMajorSelection,
        addSelectedMajors,
        selectedEntries,
        addSelectedEntries,
        updateEntrySelection,
      }}
    >
      {children}
    </MajorsContext.Provider>
  );
};

export const useMajors = () => {
  return useContext(MajorsContext);
};
