import React, { useState, useEffect } from "react";
import styles from './MapResult.module.css';
import { useMap } from "./MapContext";

const MapResult = () => {

    const [type, setType] = useState('optimistic');
    const [filteredData, setFilteredData] = useState(null);
    let tehran_A_data = [];
    let tehran_B_data = [];
    let tehran_C_data = [];
    let tehran_D_data = [];
    let tehran_PA_data = [];
    const { data, selectedProvince } = useMap();

    useEffect(() => {
        if (data.quota !== undefined) {
            localStorage.setItem('quota', data.quota);
    }
    }, [data]);

    const PROVINCE_ENUM = {
        0: "تهران",
        5: "البرز",
        6: "اردبیل",
        7: "اصفهان",
        8: "ایلام",
        9: "بوشهر",
        10: "چهارمحال و بختیاری",
        11: "فارس",
        12: "گیلان",
        13: "گلستان",
        14: "همدان",
        15: "هرمزگان",
        16: "کرمان",
        17: "کرمانشاه",
        18: "خراسان جنوبی",
        19: "خراسان رضوی",
        20: "خراسان شمالی",
        21: "خوزستان",
        22: "كهگیلویه و بویراحمد",
        23: "كردستان",
        24: "لرستان",
        25: "مركزی",
        26: "مازندران",
        27: "قزوین",
        28: "قم",
        29: "سمنان",
        30: "سیستان و بلوچستان",
        31: "آذربایجان شرقی",
        32: "آذربایجان غربی",
        33: "یزد",
        34: "زنجان"
        };

    const QUOTA_ENUM = {
    0: "منطقه 1",
    1: "منطقه 2",
    2: "منطقه 3",
    3: "5% سهمیه",
    4: "25% سهمیه",
    5: "سهمیه خانواده‌های شهدا و جانبازان",
    6: "سهمیه جانبازان",
    7: "سهمیه استعدادهای درخشان",
    8: "سهمیه اقلیت‌های قومی"
    }

    const GROUP_ENUM = {
    0: "گروه ریاضی",
    1: "گروه انسانی",
    2: "گروه تجربی",
    3: "گروه هنر",
    4: "گروه زبان",
    5: "صرفا سوابق تحصیلی"
    }

    useEffect(() => {
        const expectedMajors = data["expected majors"];
        const newProvinceData = expectedMajors ? expectedMajors[selectedProvince] : null;
        if(selectedProvince === 0){
            const typeData = newProvinceData && newProvinceData[type] || [];
            const realisticData = newProvinceData && newProvinceData['realistic'] || [];

            const filterSource = (typeData.length > 0) ? typeData : realisticData;

            tehran_A_data = filterSource.filter((item) => item[1] === 0);
            tehran_B_data = filterSource.filter((item) => item[1] === 1);
            tehran_C_data = filterSource.filter((item) => item[1] === 2);
            tehran_D_data = filterSource.filter((item) => item[1] === 3);
            tehran_PA_data = filterSource.filter((item) => item[1] === 4);

            const tehran_data = [];
            if(tehran_A_data && tehran_A_data.length > 0){
                tehran_data.push("تهران A");
                tehran_data.push(...tehran_A_data.map(item => item[0]));
            }
            if(tehran_B_data && tehran_B_data.length > 0){
                tehran_data.push("تهران B");
                tehran_data.push(...tehran_B_data.map(item => item[0]));
            }
            if(tehran_C_data && tehran_C_data.length > 0){
                tehran_data.push("تهران C");
                tehran_data.push(...tehran_C_data.map(item => item[0]));
            }
            if(tehran_D_data && tehran_D_data.length > 0){
                tehran_data.push("تهران D");
                tehran_data.push(...tehran_D_data.map(item => item[0]));
            }
            if(tehran_PA_data && tehran_PA_data.length > 0){
                tehran_data.push("تهران PA");
                tehran_data.push(...tehran_PA_data.map(item => item[0]));
            }
            setFilteredData(tehran_data.flat());
        }
        else{
            setFilteredData(newProvinceData ? newProvinceData[type].length === 0 ? newProvinceData['realistic'] : newProvinceData[type] : null);
        }
    }, [type, selectedProvince, data]);

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <div className={styles.province}>استان {PROVINCE_ENUM[selectedProvince]}</div>
                {data.study_group !== undefined &&  <div className={styles.study_group}>{GROUP_ENUM[data.study_group]}</div>}
                {data.quota !== undefined && <div className={styles.quota}>{QUOTA_ENUM[data.quota]}</div>}
            </div>
            <div className={styles.res_box}>
                <div className={styles.result}>نمایش نتایج:</div>
                <button className={styles.optimistic} 
                onClick={()=> setType('optimistic')}
                style={{borderBottom: type ==='optimistic' ? '3px solid #2F428F' : '' }}
                >خوش بینانه</button>
                <button className={styles.realistic} 
                onClick={()=> setType('realistic')}
                style={{borderBottom: type ==='realistic' ? '3px solid #2F428F' : '' }}>واقع بینانه</button>
                <button className={styles.pessimistic} 
                onClick={()=> setType('pessimistic')}
                style={{borderBottom: type ==='pessimistic' ? '3px solid #2F428F' : '' }}>بدبینانه</button>
            </div>
            {selectedProvince !== 0 &&
            <ul className={styles.majors}>
                {
                    filteredData &&
                    filteredData.map((item, index) => 
                    {
                        return <li key={index} className={styles.major}>
                            {selectedProvince <= 4 ? item[0] : item}
                            </li>
                    }) 
                }
            </ul>}
            {selectedProvince === 0 &&
            <ul className={styles.majors}>
                {
                    filteredData &&
                    filteredData.map((item, index) => 
                    {
                        return <li key={index} className={styles.major}
                        style={{fontWeight: (item === 'تهران A' || item === 'تهران B' || item === 'تهران C' || item === 'تهران D' || item === 'تهران PA') ? '900' : '300',
                        fontSize: (item === 'تهران A' || item === 'تهران B' || item === 'تهران C' || item === 'تهران D' || item === 'تهران PA') ? '.9vw' : '.8vw'}}>
                            {item}
                            </li>
                    }) 
                }
            </ul>
            }
            {filteredData && filteredData.length === 0 && <p className={styles.not_found}>رشته ای یافت نشد!</p>}
            {data.study_group === undefined && <p className={styles.not_found}>رشته ای یافت نشد!</p>}
        </div>
    )
}

export default MapResult;