import React, { useState, useRef, useEffect } from "react";
import LoginImg from "../../images/login-img.png";
import Logo from "../../images/youtab-logo.png";
import styles from "./Login.module.css";
import { useAuth } from "./AuthContext";

const Login = () => {
  const { login, hasError } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const usernameRef = useRef(null);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <img src={LoginImg} alt="login" />
      <div className={styles.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={styles.title}>ورود به سایت</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          className={styles.username}
          type="text"
          placeholder="نام کاربری/ایمیل"
          value={username}
          onChange={handleUsernameChange}
          ref={usernameRef}
        />
        <input
          className={styles.password}
          type="password"
          placeholder="رمز عبور"
          value={password}
          onChange={handlePasswordChange}
        />
        <button className={styles.login} type="submit">
          ورود
        </button>
      </form>
      {hasError && (
        <p className={styles.error}> !کاربری با مشخصات وارد شده یافت نشد</p>
      )}
    </div>
  );
};

export default Login;
