import React from 'react';
import ReactDOM from 'react-dom';
import MatrixModal from './MatrixModal';

const MatrixPortal = ({ isOpen, onClose, itemId}) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <MatrixModal onClose={onClose} itemId={itemId}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default MatrixPortal;