import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfoMajorModal from './MoreInfoMajorModal';

const MoreInfoMajorPortal = ({ isOpen, onClose, similarMajors, major }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <MoreInfoMajorModal onClose={onClose} similarMajors={similarMajors} major={major}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default MoreInfoMajorPortal;