import React, { createContext, useContext, useEffect, useState } from "react";

const ProvincesContext = createContext();

export const ProvincesProvider = ({ children }) => {
  const [allProvinces, setAllProvinces] = useState(() => {
    const storedProvinces = localStorage.getItem("allProvinces");
    return storedProvinces ? JSON.parse(storedProvinces) : [];
  });

  const [selectedProvinces, setSelectedProvinces] = useState([]);

  const removeProvince = (id) => {
    const updatedSelectedProvinces = selectedProvinces.filter(
      (province) => province.id !== id
    );
    setSelectedProvinces(updatedSelectedProvinces);

    setAllProvinces((prevProvinces) =>
      prevProvinces.map((province) =>
        province.id === id ? { ...province, selected: false } : province
      )
    );
  };

  const addSelectedProvinces = () => {
    const selectedItems = allProvinces.filter((province) => province.selected);
    setSelectedProvinces(selectedItems);
  };

  const updateProvinceSelection = (id, selected) => {
    setAllProvinces((prevProvinces) =>
      prevProvinces.map((province) =>
        province.id === id ? { ...province, selected } : province
      )
    );
  };

  useEffect(() => {
    const selectedItems = allProvinces.filter((province) => province.selected);
    setSelectedProvinces(selectedItems);
    localStorage.setItem("allProvinces", JSON.stringify(allProvinces));
  }, [allProvinces]);

  return (
    <ProvincesContext.Provider
      value={{
        allProvinces,
        setAllProvinces,
        selectedProvinces,
        setSelectedProvinces,
        removeProvince,
        updateProvinceSelection,
        addSelectedProvinces,
      }}
    >
      {children}
    </ProvincesContext.Provider>
  );
};

export const useProvinces = () => {
  return useContext(ProvincesContext);
};
