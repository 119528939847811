import React, { useState } from "react";
import styles from "./CartItem.module.css";
import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkSharp } from "react-icons/io5";
import { GoArrowSwitch } from "react-icons/go";
import ChangePriorityPortal from "./ChangePriorityPortal";

const CartItem = ({
  item,
  setItems,
  index,
  onDragStart,
  onDragOver,
  onDrop,
  onPriorityChange,
  numOfItems,
  showChance,
}) => {
  const [isChangePopUpOpen, setIsChangePopUpOpen] = useState(false);

  const handleToggle = () => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((it) =>
        it.id === item.id ? { ...it, isSelected: !it.isSelected } : it
      );
      return updatedItems;
    });
  };

  const changePriority = () => {
    setIsChangePopUpOpen(true);
  };

  const closePopUp = () => {
    setIsChangePopUpOpen(false);
  };

  const sexEnum = {
    1: "مرد",
    2: "زن",
    3: "مرد - زن",
  };

  const shiftEnum = {
    0: "نیم‌سال اول",
    1: "نیم‌سال دوم",
    2: "نیم‌سال اول و دوم",
  };

  const ENTRY_ENUM = {
    0: "روزانه",
    1: "نوبت دوم",
    2: "مشترک",
    3: "غیرانتفاعی",
    4: "پیام نور",
    5: "شهريه پرداز",
    6: "پرديس خودگردان",
    7: "سیل زدگان",
    8: "مخصوص داوطلبان بومی شهرستانهای جنوب استان کرمان",
    9: "مخصوص داوطلبان شهرستان بشاگرد",
    10: "صرفا سوابق تحصیلی وزارت بهداشت",
    11: "مناطق محروم",
    12: "مجازی",
    13: "تعهدی وزارت بهداشت",
    14: "تعهدی پزشکی- شورای عالی انقلاب",
    15: "آزاد تمام وقت",
    16: "خودگردان آزاد",
    17: "بورسیه دانش",
    18: "بورسیه صنعتی",
    19: "بورسیه سایپا",
    20: "بورسیه انرژی اتمی",
    21: "تربیت مدرس",
  };

  const PROVINCE_ENUM = {
    0: "تهران",
    1: "تهران",
    2: "تهران",
    3: "تهران",
    4: "تهران",
    5: "البرز",
    6: "اردبیل",
    7: "اصفهان",
    8: "ایلام",
    9: "بوشهر",
    10: "چهارمحال و بختیاری",
    11: "فارس",
    12: "گیلان",
    13: "گلستان",
    14: "همدان",
    15: "هرمزگان",
    16: "کرمان",
    17: "کرمانشاه",
    18: "خراسان جنوبی",
    19: "خراسان رضوی",
    20: "خراسان شمالی",
    21: "خوزستان",
    22: "كهگیلویه و بویراحمد",
    23: "كردستان",
    24: "لرستان",
    25: "مركزی",
    26: "مازندران",
    27: "قزوین",
    28: "قم",
    29: "سمنان",
    30: "سیستان و بلوچستان",
    31: "آذربایجان شرقی",
    32: "آذربایجان غربی",
    33: "یزد",
    34: "زنجان",
  };

  const labelColors = {
    روزانه: ["#0092A4", "#CBF2F7"],
    "نوبت دوم": ["#2E4290", "#CDDAFD"],
    غیرانتفاعی: ["#FF00C7", "#FFDBF7"],
    "پرديس خودگردان": ["#FF000F", "#FFE0E0"],
    "شهريه پرداز": ["#0043FF", "#DFE7FD"],
    "پیام نور": ["#FF7000", "#FFF1E6"],
    مشترک: ["#EFAD00", "#FFF9D8"],
    "آزاد تمام وقت": ["#8C3400", "#E2CFC4"],
    "خودگردان آزاد": ["#8C3400", "#E2CFC4"],
    "بورسیه دانش": ["#0092A4", "#CBF2F7"],
    "بورسیه صنعتی": ["#0092A4", "#CBF2F7"],
    "تربیت مدرس": ["#0092A4", "#CBF2F7"],
    "تعهدی پزشکی- شورای عالی انقلاب": ["#0092A4", "#CBF2F7"],
    "تعهدی وزارت بهداشت": ["#0092A4", "#CBF2F7"],
    "سیل زدگان": ["#0092A4", "#CBF2F7"],
    "صرفا سوابق تحصیلی وزارت بهداشت": ["#0092A4", "#CBF2F7"],
    "مخصوص داوطلبان بومی شهرستانهای جنوب استان کرمان": ["#0092A4", "#CBF2F7"],
    "مخصوص داوطلبان شهرستان بشاگرد": ["#0092A4", "#CBF2F7"],
    "مناطق محروم": ["#0092A4", "#CBF2F7"],
    مجازی: ["#9961EC", "#ECE0FF"],
  };

  const scenarioColors = {
    "no data": "#00FFFF",
    optimistic: "#00A47B",
    realistic: "#EFA300",
    pessimistic: "#DB1D1D",
  };

  return (
    <li
      key={`${item.id}-${item.code}-${item.name}-${item.university}`}
      className={styles.item}
      style={{ marginBottom: item.description !== "nan" ? "2.7vw" : ".6vw" }}
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e, index)}
    >
      <div className={styles.number}>{index + 1}</div>
      <div className={styles.edit}>
        <div className={styles.change}>
          <GoArrowSwitch onClick={changePriority} />
          <ChangePriorityPortal
            isOpen={isChangePopUpOpen}
            onClose={closePopUp}
            itemId={item.id}
            onPriorityChange={onPriorityChange}
            currentIndex={index}
            numOfItems={numOfItems}
          />
        </div>
        <div
          className={styles.tick}
          onClick={handleToggle}
          style={{ color: item.isSelected ? "#F10000" : "#00C34E" }}
        >
          {item.isSelected ? <RxCross2 /> : <IoCheckmarkSharp />}
        </div>
      </div>
      <div
        className={styles.data}
        style={{ backgroundColor: !item.isSelected ? "#FFE0E0" : "white" }}
      >
        {showChance && (
          <div
            className={styles.circle}
            style={{ backgroundColor: scenarioColors[item.scenario] }}
          ></div>
        )}
        &nbsp;
        <div className={styles.major}>
          {item.name}
          &nbsp; | &nbsp;
        </div>
        <div className={styles.uni}>{item.university}&nbsp; | &nbsp;</div>
        <div
          className={styles.label}
          style={{
            backgroundColor: labelColors[ENTRY_ENUM[item.entry_type]][1],
            color: labelColors[ENTRY_ENUM[item.entry_type]][0],
          }}
        >
          {ENTRY_ENUM[item.entry_type]}
        </div>
        &nbsp; | &nbsp;
        <div className={styles.city}>{PROVINCE_ENUM[item.province]}</div>
        &nbsp; | &nbsp;
        <div>{sexEnum[item.sex]}</div>
        &nbsp; | &nbsp;
        <div>{shiftEnum[item.shift]}</div>
      </div>
      {item.description !== "nan" && (
        <div className={styles.description}>توضیحات: {item.description}</div>
      )}
    </li>
  );
};

export default CartItem;
