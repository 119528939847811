import React from "react";
import styles from './Schools.module.css';
import School1 from '../images/school1.png';
import School2 from '../images/school2.png';
import School3 from '../images/school3.png';
import School4 from '../images/school4.png';
import School5 from '../images/school5.png';
import School6 from '../images/school6.png';
import School7 from '../images/school7.png';

const Schools = () => {

    const schools = [
        School1,
        School6,
        School3,
        School4,
        School5,
        School7,
        School2
    ]

    return(
        <div className={styles.wrapper}>
            {/* <ul className={styles.schools}>
                {schools.map((school, index) => {
                    return <li key={index}>
                        <img src={school} alt="school" className={styles.school_item}/>
                    </li>
                })}
            </ul> */}
            <title className={styles.title}>یوتاب: نامی باشکوه و داستانی حماسی</title>
            <p className={styles.text}>یوتاب، نامی دخترانه با ریشه پارسی، 
                به معنای "درخشنده" و "بی مانند" است. این نام در تاریخ ایران، به بانویی
                 شجاع و جنگجو به نام یوتاب اشاره دارد که در دوره هخامنشیان و در نبرد
                 با اسکندر مقدونی، رشادتی مثال زدنی از خود نشان داد.
                <br/>
یوتاب خواهر آریوبرزن، ساتراپ پارس بود. هنگامی که
 اسکندر به دروازه های پارس رسید، آریوبرزن با سپاهی اندک در برابر سپاه پرشمار اسکندر
 ایستادگی کرد. یوتاب نیز دوشادوش برادر در این نبرد حماسی حضور
 داشت و شجاعانه جنگید. تاریخ نگاران یونانی، از جمله هرودوت،
 از رشادت و شجاعت یوتاب در این نبرد روایت کرده اند.
                <br/>
                این روایات، الهام بخش ما برای انتخاب نامی بوده که نشان دهنده بی مانند بودن و شجاعت مجموعه ما باشد.
                <div className=""></div>
                <div className=""></div></p>
        </div>
    )
}

export default Schools;