import React, { useEffect, useRef, useState } from "react";
import Cell from "./Cell";
import ChooseMajDurPortal from "./ChooseMajDurPortal";
import DraggableMajor from "./DraggableMajor";
import { useMatrix } from "./MatrixContext";
import styles from "./Table.module.css";

const Table = () => {
  const [tableWidth, setTableWidth] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [majors, setMajors] = useState(
    JSON.parse(localStorage.getItem("selected-majors") || "[]")
  );
  const {
    entProvList,
    setEntProvList,
    setMatrixData,
    orders,
    setOrders,
    wholeData,
    setWholeData,
  } = useMatrix();
  const [selectedProvinces, setSelectedProvinces] = useState(
    JSON.parse(localStorage.getItem("selected-provinces") || "[]")
  );
  const [selectedEntries, setSelectedEntries] = useState(
    JSON.parse(localStorage.getItem("selected-entries") || "[]")
  );

  const isCenter = {
    0: "حومه",
    1: "مرکز",
  };

  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      const width = tableRef.current.clientWidth;
      setTableWidth(width);
    }
  }, [entProvList]);

  useEffect(() => {
    setMatrixData((prevMatrixData) => {
      const majors = JSON.parse(
        localStorage.getItem("selected-majors") || "[]"
      );
      const newEntries = majors.flatMap((major) =>
        entProvList.map((entryProv) => ({
          id: `${major.name}-${entryProv.province}-${entryProv.entry}-${entryProv.isCenter}`,
          entryProv: entryProv,
          majorName: major.name,
        }))
      );
      return [...prevMatrixData, ...newEntries];
    });

    const filteredOrders = orders.filter((order) => {
      if (!order.id) return false;
      const majorNameInOrder = order.id.split("-")[0];
      return majors.some((major) => major.name === majorNameInOrder);
    });
    setOrders([...filteredOrders]);
  }, [entProvList, majors]);

  useEffect(() => {
    const filteredOrders = orders.filter((order) => {
      if (!order.id) return false;
      const orderParts = order.id.split("-");
      const provNameInOrder = orderParts[1];
      const entNameInOrder = orderParts[2];
      return (
        selectedProvinces.some((prov) => provNameInOrder.includes(prov.name)) &&
        selectedEntries.some((ent) => entNameInOrder.includes(ent.name))
      );
    });
    setOrders(filteredOrders);
    const filteredEntProvs = entProvList.filter(
      (entProv) =>
        selectedProvinces.some((prov) =>
          entProv.province.includes(prov.name)
        ) && selectedEntries.some((ent) => entProv.entry.includes(ent.name))
    );
    setEntProvList(filteredEntProvs);
  }, [selectedProvinces, selectedEntries]);

  useEffect(() => {
    const orderKeys = new Set(orders.map((order) => order.id));
    const filteredData = Object.keys(wholeData).reduce((acc, key) => {
      if (orderKeys.has(key)) {
        acc[key] = wholeData[key];
      }
      return acc;
    }, {});
    setWholeData(filteredData);
  }, [orders]);

  const openPopUp = () => {
    setIsPopUpOpen(true);
  };

  const closePopUp = () => {
    setIsPopUpOpen(false);
  };

  const matrixData = JSON.parse(localStorage.getItem("matrix-data") || "[]");

  const getItem = (major, entProv) => {
    return (
      matrixData.find(
        (item) =>
          item.majorName === major.name &&
          item.entryProv.entry === entProv.entry &&
          item.entryProv.province === entProv.province &&
          item.entryProv.isCenter === entProv.isCenter
      ) || {}
    );
  };

  const moveMajor = (fromIndex, toIndex) => {
    const updatedMajors = [...majors];
    const [movedMajor] = updatedMajors.splice(fromIndex, 1);
    updatedMajors.splice(toIndex, 0, movedMajor);
    setMajors(updatedMajors);
    localStorage.setItem("selected-majors", JSON.stringify(updatedMajors));
  };

  return (
    <>
      <div className={styles.table_container}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th className={styles.cityHeader}>رشته، استان و دوره</th>
              {entProvList.map((entProv, index) => (
                <th key={index}>
                  {`${entProv.entry} | ${entProv.province}`}
                  {!entProv.province.includes("تهران") &&
                    ` (${isCenter[entProv.isCenter]})`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {majors.map((major, majorIndex) => (
              <tr key={majorIndex}>
                <td className={styles.cityCell}>
                  <DraggableMajor
                    major={major}
                    index={majorIndex}
                    moveMajor={moveMajor}
                  />
                </td>
                {entProvList.map((entProv, index) => (
                  <Cell key={index} item={getItem(major, entProv)} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className={styles.add}
          style={{
            right: tableWidth
              ? `calc(${(tableWidth / window.innerWidth) * 100}vw`
              : "5vw",
          }}
          onClick={openPopUp}
        >
          اضافه کردن ستون استان/دوره+
        </button>
        <ChooseMajDurPortal isOpen={isPopUpOpen} onClose={closePopUp} />
      </div>
    </>
  );
};

export default Table;
