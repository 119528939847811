import React, { useState } from "react";
import styles from './DurationItem.module.css';
import { PiInfoLight } from "react-icons/pi";
import MoreInfoDurationPortal from "./MoreInfoDurationPortal";

const DurationItem = ({ item, handleCheckboxChange }) => {

    const ENTRY_INFO = {
        "بورسیه سایپا": '',
        "بورسیه انرژی اتمی": '',
        'روزانه': '',
        'نوبت دوم': 'تابع مقررات دوره روزانه، عدم امکان انتقال به دوره روزانه، اخذ شهریه، برای توضیحات بیشتر به دفترچه انتخاب رشته ۱۴۰۳ سازمان سنجش مراجعه شود.',
        'مشترک': 'دارای مصاحبه، برای توضیحات بیشتر به دفترچه انتخاب رشته ۱۴۰۳ سازمان سنجش مراجعه شود.',
        'غیرانتفاعی': 'اخذ شهریه، عدم تعهد در تامین مسکن و خواب‌گاه.',
        'پیام نور': 'اخذ شهریه، حضور در کلاس‌های تئوری اختیاریست، برای توضیحات بیشتر به دفترچه انتخاب رشته ۱۴۰۳ سازمان سنجش مراجعه شود.',
        'شهريه پرداز': 'اخذ شهریه، عدم ذکر نوع دوره در مدرک تحصیلی.',
        'پرديس خودگردان': 'اخذ شهریه، غالبا محل تشکیل کلاس‌های این دوره از محل تشکیل کلاس‌های دوره روزانه جدا است اما اساتید و آموزش یکی هستند',
        'سیل زدگان': 'دارای تعهد خدمت، انتخاب و قبولی در کد رشته‌های این دوره دارای ضوابط و شرایط خاص می‌باشد که نیازمند مطالعه دقیق دفترچه انتخاب رشته سازمان سنجش می‌باشد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'مخصوص داوطلبان بومی شهرستانهای جنوب استان کرمان': 'دارای تعهد خدمت، انتخاب و قبولی در کد رشته‌های این دوره دارای ضوابط و شرایط خاص می‌باشد که نیازمند مطالعه دقیق دفترچه انتخاب رشته سازمان سنجش می‌باشد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'مخصوص داوطلبان شهرستان بشاگرد': 'دارای تعهد خدمت، انتخاب و قبولی در کد رشته‌های این دوره دارای ضوابط و شرایط خاص می‌باشد که نیازمند مطالعه دقیق دفترچه انتخاب رشته سازمان سنجش می‌باشد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'صرفا سوابق تحصیلی وزارت بهداشت': 'دارای تعهد خدمت، دارای مصاحبه، برای انتخاب کد رشته از این دوره نیاز به تطبیق استان و شهرستان بومی درج شده در کارنامه کنکور با توضیحات مربوط به کد رشته وجود دارد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'مناطق محروم': 'پذیرش فقط از کد رشته‌های استان بومی درج شده در کارنامه کنکور، دارای تعهد خدمت. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'مجازی': 'تحصیل در اسن دوره به صورت مجازی انجام می‌شود.',
        'تعهدی وزارت بهداشت': 'دارای تعهد خدمت، انتخاب و قبولی در کد رشته‌های این دوره دارای ضوابط و شرایط خاص می‌باشد که نیازمند مطالعه دقیق دفترچه انتخاب رشته سازمان سنجش می‌باشد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'تعهدی پزشکی- شورای عالی انقلاب': 'اولویت در هر استان با داوطلبان بومی همان استان است، دارای تعهد خدمت، برای انتخاب کد رشته از این دوره نیاز به تطبیق استان و شهرستان بومی درج شده در کارنامه کنکور با توضیحات مربوط به کد رشته وجود دارد. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'آزاد تمام وقت': ' اخذ شهریه.',
        'خودگردان آزاد': 'اخذ شهریه ۲ برابر دوره آزاد تمام وقت.',
        'بورسیه دانش': 'انتخاب کد رشته از این دوره ملزم به کسب حداقل رتبه کشوری می‌باشد، دارای مصاحبه، حمایت مالی ماهیانه، برای توضیحات بیشتر به دفترچه انتخاب رشته ۱۴۰۳ سازمان سنجش مراجعه شود. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        'بورسیه صنعتی': 'به منظور اطلاع بیشتر از شرایط دوره به دفترچه انتخاب رشته ۱۴۰۳ سازمان سنجش مراجعه شود. برای انتخاب این دوره، مراکز استان مورد نظر را انتخاب کنید.',
        "تربیت مدرس" : ''
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };
    return (
        <li key={item.id} className={styles.duration}>
            <input
                type="checkbox"
                checked={item.selected}
                onChange={() => handleCheckboxChange(item.id, !item.selected)}
            />
            <label className={styles.name_text}>{item.name}</label>
            {ENTRY_INFO[item.name] !== '' && <PiInfoLight onClick={openModal} />}
            {ENTRY_INFO[item.name] !== '' && <MoreInfoDurationPortal isOpen={isModalOpen} onClose={closeModal} text={ENTRY_INFO[item.name]}/>}
        </li>
    );
};

export default DurationItem;