import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../login/AuthContext';

const KonkoorContext = createContext();

export const useKonkoorContext = () => {
    return useContext(KonkoorContext);
};

export const KonkoorProvider = ({ children }) => {

    const [hasSecondKonkoor, setHasSecondKonkoor] = useState(false);
    const [hasThirdKonkoor, setHasThirdKonkoor] = useState(false);
    const { user } = useAuth();

    const [formData, setFormData] = useState({
        first_name: user ? user.first_name : '',
        last_name: user ? user.last_name : '',
        institute: user ? user.institute : '',
        quota: '',
        quotaMartyr: '',
        sex: '',
        province: ''
    });

    const [firstKonkoorData, setFirstKonkoorData] = useState({
        student: '',
        study_group: '',
        main_language: null,
        eligible_exam_entry: [],
        eligible_record_entry: [],
        score: '',
        rank: '',
        region_rank: ''
    });

    const [secondKonkoorData, setSecondKonkoorData] = useState({
        student: '',
        study_group: '',
        main_language: null,
        eligible_exam_entry: [],
        eligible_record_entry: [],
        score: '',
        rank: '',
        region_rank: ''
    });

    const [thirdKonkoorData, setThirdKonkoorData] = useState({
        student: '',
        study_group: '',
        main_language: null,
        eligible_exam_entry: [],
        eligible_record_entry: [],
        score: '',
        rank: '',
        region_rank: ''
    });

    useEffect(() => {
        if (user && user.national_code) {
            setFirstKonkoorData(prevData => ({
                ...prevData,
                student: user.national_code
            }));

            setSecondKonkoorData(prevData => ({
                ...prevData,
                student: user.national_code
            }));

            setThirdKonkoorData(prevData => ({
                ...prevData,
                student: user.national_code
            }));

            setFormData(prevData => ({
                ...prevData,
                first_name: user.first_name,
                last_name: user.last_name,
                institute: user.institute
            }));
        }
    }, [user, hasSecondKonkoor, hasThirdKonkoor]);


    return (
        <KonkoorContext.Provider value={{ firstKonkoorData, setFirstKonkoorData,
        secondKonkoorData, setSecondKonkoorData,
        thirdKonkoorData, setThirdKonkoorData,
        hasSecondKonkoor, setHasSecondKonkoor,
        hasThirdKonkoor, setHasThirdKonkoor,
        formData, setFormData }}>
            {children}
        </KonkoorContext.Provider>
    );
};
