import React, { useRef, useEffect } from "react";
import styles from './MoreInfoMajorModal.module.css';

const MoreInfoMajorModal = ({ onClose, similarMajors, major }) => {


    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}> 
            <div className={styles.title}>:رشته های مشابه {major.name}</div>
            {similarMajors.length > 0 ?
                <ul className={styles.similar_majors}>
                    {similarMajors.map((similarMajor, index) => (
                        <li key={index} className={styles.simi_major}>{similarMajor}</li>
                    ))}
                </ul>
                :
                <div className={styles.not_found}>رشته مشابهی یافت نشد!</div>
            }
            </div>
        </div>
    ); 
}

export default MoreInfoMajorModal;