import React from 'react';
import Home from './components/home/Home';
import EnterInfo from './components/konkoor_info/EnterInfo';
import Map from './components/map/Map';
import CartChin from './components/cart-chin/CartChin';
import MajorsAndProvinces from './components/majors_and_provinces/MajorsAndProvinces';
import Matrix from './components/matrix/Matrix';
import Login from './components/login/Login';
import AboutUs from './about-us/AboutUs';
import PDF from './components/pdf/PDF';
import { Route, Routes } from 'react-router-dom';
function AppRoutes() {

    return (
    <Routes>
        <Route path="/start" element={<Home />} />
        <Route path="/course-selection" element={<EnterInfo />} />
        <Route path="/map" element={<Map />} />
        <Route path="/majors-and-provinces" element={<MajorsAndProvinces />} />
        <Route path="/cart-chin" element={<CartChin />} />
        <Route path="/matrix" element={<Matrix />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<AboutUs />} />
        <Route path="/pdf" element={<PDF />} />
    </Routes>
);
}

export default AppRoutes;