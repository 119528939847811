import React from "react";
import styles from './Majors.module.css';
import Major from "./Major";
import { useMajors } from "../MajorsContext";
import { CiSearch } from "react-icons/ci";

const Majors = () => {

    const { selectedMajors } = useMajors();

    return(
        selectedMajors.length === 0 ? 
        <div className={styles.no_chosen}>
            <CiSearch className={styles.search_icon}/>
            هنوز رشته ای انتخاب نشده است!</div>
        :
        <ul className={styles.wrapper}>
            {selectedMajors.map((major, index) => (
                <Major key={index} major={major} index={index} />
            ))}
        </ul>
    )
}

export default Majors;