import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../login/AuthContext";
import styles from "./KonkoorDetail.module.css";

const KonkoorDetail = () => {
  const [stuInfo, setStuInfo] = useState({});
  const [konkoorInfo, setKonkoorInfo] = useState([]);

  const { onSetAccountType } = useAuth();
  const ENTRY_ENUM = {
    0: "روزانه",
    1: "نوبت دوم",
    2: "مشترک",
    3: "غیرانتفاعی",
    4: "پیام نور",
    5: "شهريه پرداز",
    6: "پرديس خودگردان",
    7: "سیل زدگان",
    8: "داوطلبان جنوب کرمان",
    9: "مخصوص داوطلبان شهرستان بشاگرد",
    10: "صرفا سوابق تحصیلی وزارت بهداشت",
    11: "مناطق محروم",
    12: "مجازی",
    13: "تعهدی وزارت بهداشت",
    14: "تعهدی پزشکی- شورای عالی انقلاب",
    15: "آزاد تمام وقت",
    16: "خودگردان آزاد",
    17: "بورسیه دانش",
    18: "بورسیه صنعتی",
    19: "بورسیه سایپا",
    20: "بورسیه انرژی اتمی",
    21: "تربیت مدرس"
  };

  const PROVINCE_ENUM = {
    0: "تهران",
    5: "البرز",
    6: "اردبیل",
    7: "اصفهان",
    8: "ایلام",
    9: "بوشهر",
    10: "چهارمحال و بختیاری",
    11: "فارس",
    12: "گیلان",
    13: "گلستان",
    14: "همدان",
    15: "هرمزگان",
    16: "کرمان",
    17: "کرمانشاه",
    18: "خراسان جنوبی",
    19: "خراسان رضوی",
    20: "خراسان شمالی",
    21: "خوزستان",
    22: "كهگیلویه و بویراحمد",
    23: "كردستان",
    24: "لرستان",
    25: "مركزی",
    26: "مازندران",
    27: "قزوین",
    28: "قم",
    29: "سمنان",
    30: "سیستان و بلوچستان",
    31: "آذربایجان شرقی",
    32: "آذربایجان غربی",
    33: "یزد",
    34: "زنجان",
  };

  const QUOTA_ENUM = {
    0: "منطقه 1",
    1: "منطقه 2",
    2: "منطقه 3",
    3: "5% سهمیه",
    4: "25% سهمیه",
    5: "سهمیه خانواده‌های شهدا و جانبازان",
    6: "سهمیه جانبازان",
    7: "سهمیه استعدادهای درخشان",
    8: "سهمیه اقلیت‌های قومی",
  };

  const GROUP_ENUM = {
    0: "گروه ریاضی",
    1: "گروه انسانی",
    2: "گروه تجربی",
    3: "گروه هنر",
    4: "گروه زبان",
    5: "صرفا سوابق تحصیلی",
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          axios.get("https://youtabteam.ir/api/students/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get("https://youtabteam.ir/api/konkoor/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        onSetAccountType(response1.data.account_type);
        localStorage.setItem("account_type", response1.data.account_type);
        setStuInfo(response1.data);
        setKonkoorInfo(response2.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className={styles.student_info}>
        <div className={styles.title}>اطلاعات دانش آموز:</div>
        <div className={styles.info}>
          <div
            className={styles.fullname}
          >{`${stuInfo.first_name} ${stuInfo.last_name}`}</div>
          <div className={styles.institute}>{stuInfo.institute}</div>
          <div className={styles.province}>
            {PROVINCE_ENUM[stuInfo.province]}
          </div>
          <div className={styles.quota}>{QUOTA_ENUM[stuInfo.quota]}</div>
        </div>
      </div>
      <div className={styles.table_wrapper}>
        <table className={styles.konkoor_table}>
          <thead>
            <tr>
              <th className={styles.major}>رشته</th>
              <th className={styles.record}>تراز کل</th>
              <th className={styles.rank}>رتبه کشوری</th>
              <th className={styles.exam}>دوره های مجاز با آزمون</th>
              <th className={styles.record}>دوره های مجاز با سوابق</th>
            </tr>
          </thead>
          <tbody>
            {konkoorInfo.map((konkoor, index) => {
              const examArray = JSON.parse(konkoor.eligible_exam_entry || "[]");
              const recordArray = JSON.parse(
                konkoor.eligible_record_entry || "[]"
              );

              const exam = examArray
                .map((item) => ENTRY_ENUM[item] || "Unknown")
                .join(", ");
              const record = recordArray
                .map((item) => ENTRY_ENUM[item] || "Unknown")
                .join(", ");
              return (
                <tr key={index}>
                  <td className={styles.item1}>
                    {GROUP_ENUM[konkoor.study_group]}
                  </td>
                  <td className={styles.item2}>{konkoor.score}</td>
                  <td className={styles.item3}>{konkoor.rank}</td>
                  <td className={styles.item4}>{exam}</td>
                  <td className={styles.item5}>{record}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default KonkoorDetail;
