import React, { useRef, useEffect } from "react";
import styles from './MoreInfoDurationModal.module.css';

const MoreInfoDurationModal = ({ text, onClose }) => {


    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}> 
                <p className={styles.text}>{text}</p>
            </div>
        </div>
    ); 
}

export default MoreInfoDurationModal;