import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthContext";
import { useMajors } from "../majors_and_provinces/MajorsContext";
import { useProvinces } from "../majors_and_provinces/ProvincesContext";
import styles from "./ControlButtons.module.css";
import { useKonkoorContext } from "./KonkoorContext";
import NextKonkoorModal from "./NextKonkoorModal";

const ControlButtons = () => {
  const navigate = useNavigate();

  const { setSelectedMajors, setAllEntries } = useMajors();
  const { setSelectedProvinces } = useProvinces();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notCompleteError, setNotCompleteError] = useState(false);
  const { user, accountType } = useAuth();
  const isGlobal = accountType === "0";

  const {
    hasSecondKonkoor,
    setHasSecondKonkoor,
    hasThirdKonkoor,
    setHasThirdKonkoor,
    setFirstKonkoorData,
    setSecondKonkoorData,
    setThirdKonkoorData,
    firstKonkoorData,
    secondKonkoorData,
    thirdKonkoorData,
    formData,
  } = useKonkoorContext();

  const openModal = () => {
    let error = false;

    if (
      formData.province === "" ||
      formData.quota === "" ||
      formData.quotaMartyr === "" ||
      formData.sex === "" ||
      formData.province === "انتخاب کنید" ||
      formData.quota === "انتخاب کنید" ||
      formData.quotaMartyr === "انتخاب کنید" ||
      formData.sex === "انتخاب کنید"
    ) {
      error = true;
    }
    if (
      firstKonkoorData.study_group === "" ||
      firstKonkoorData.rank === "" ||
      firstKonkoorData.regionRank === "" ||
      firstKonkoorData.score === "" ||
      (firstKonkoorData.study_group === "4" &&
        firstKonkoorData.main_language === "")
    ) {
      error = true;
    }
    if (
      hasSecondKonkoor &&
      (secondKonkoorData.study_group === "" ||
        secondKonkoorData.rank === "" ||
        secondKonkoorData.regionRank === "" ||
        secondKonkoorData.score === "" ||
        (secondKonkoorData.study_group === "4" &&
          secondKonkoorData.main_language === ""))
    ) {
      error = true;
    }
    if (
      hasThirdKonkoor &&
      (thirdKonkoorData.study_group === "" ||
        thirdKonkoorData.rank === "" ||
        thirdKonkoorData.regionRank === "" ||
        thirdKonkoorData.score === "" ||
        (thirdKonkoorData.study_group === "4" &&
          thirdKonkoorData.main_language === ""))
    ) {
      error = true;
    }

    setNotCompleteError(error);

    if (!error) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddClick = () => {
    if (!hasSecondKonkoor) {
      setHasSecondKonkoor(true);
    } else {
      setHasThirdKonkoor(true);
    }
  };

  const handleCancelClick = () => {
    setFirstKonkoorData({
      student: user && user.national_code,
      study_group: "",
      main_language: "",
      eligible_exam_entry: [],
      eligible_record_entry: [],
      score: "",
      rank: "",
    });
    setSecondKonkoorData({
      student: user && user.national_code,
      study_group: "",
      main_language: "",
      eligible_exam_entry: [],
      eligible_record_entry: [],
      score: "",
      rank: "",
    });
    setThirdKonkoorData({
      student: user && user.national_code,
      study_group: "",
      main_language: "",
      eligible_exam_entry: [],
      eligible_record_entry: [],
      score: "",
      rank: "",
    });
  };

  const marginTop = () => {
    if (!hasSecondKonkoor) return "1vw";
    else if (!hasThirdKonkoor) return "2vw";
    else return "3vw";
  };

  const makeKonkoors = async () => {
    setSelectedMajors([]);
    setSelectedProvinces([]);
    setAllEntries([]);
    const token = localStorage.getItem("token");
    const url = "https://youtabteam.ir/api/konkoor/";
    const putUrl = "https://youtabteam.ir/api/students/";

    try {
      const putData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        institute: formData.institute,
        quota:
          formData.quotaMartyr === "ندارم"
            ? formData.quota
            : formData.quotaMartyr,
        sex: formData.sex,
        province: formData.province,
      };

      await axios.put(putUrl, putData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (user && firstKonkoorData.study_group) {
        const firstResponse = await axios.post(
          url,
          {
            ...firstKonkoorData,
            eligible_exam_entry: JSON.stringify(
              firstKonkoorData.eligible_exam_entry
            ),
            eligible_record_entry: JSON.stringify(
              firstKonkoorData.eligible_record_entry
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (user && hasSecondKonkoor && secondKonkoorData.study_group) {
        const secondResponse = await axios.post(
          url,
          {
            ...secondKonkoorData,
            eligible_exam_entry: JSON.stringify(
              secondKonkoorData.eligible_exam_entry
            ),
            eligible_record_entry: JSON.stringify(
              secondKonkoorData.eligible_record_entry
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (user && hasThirdKonkoor && thirdKonkoorData.study_group) {
        const thirdResponse = await axios.post(
          url,
          {
            ...thirdKonkoorData,
            eligible_exam_entry: JSON.stringify(
              thirdKonkoorData.eligible_exam_entry
            ),
            eligible_record_entry: JSON.stringify(
              thirdKonkoorData.eligible_record_entry
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      console.error(
        "Error making requests:",
        error.response ? error.response.data : error.message
      );
    }
    closeModal();
    if (isGlobal) {
      navigate("/map");
    } else {
      navigate("/majors-and-provinces");
    }
  };

  return (
    <div className={styles.wrapper} style={{ marginTop: marginTop() }}>
      {!hasThirdKonkoor && (
        <button className={styles.add_second} onClick={handleAddClick}>
          {!hasSecondKonkoor
            ? "اضافه کردن کنکور دوم +"
            : "اضافه کردن کنکور سوم +"}
        </button>
      )}
      <button className={styles.next} onClick={openModal}>
        مرحله بعدی
      </button>
      <button className={styles.cancel} onClick={handleCancelClick}>
        انصراف
      </button>
      <NextKonkoorModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={makeKonkoors}
      />
      {notCompleteError && (
        <div className={styles.not_complete}>
          اطلاعات وارد شده کامل نمی باشد!
        </div>
      )}
    </div>
  );
};

export default ControlButtons;
