import axios from "axios";
import React, { useEffect } from "react";
import { useHeadline } from "../konkoor_info/HeadlineContext";
import { useAuth } from "../login/AuthContext";
import styles from "./Content.module.css";
import Duration from "./durations/Duration";
import Priorities from "./majors/Priorities";
import { useMajors } from "./MajorsContext";
import Provinces from "./provinces/Provinces";
import { useProvinces } from "./ProvincesContext";

const Content = () => {
  const { setAllMajors, allMajors, setAllEntries, allEntries } = useMajors();
  const { setAllProvinces, allProvinces } = useProvinces();
  const {
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
  } = useHeadline();

  const provinces = [
    "تهران",
    "البرز",
    "اردبیل",
    "اصفهان",
    "ایلام",
    "بوشهر",
    "چهارمحال و بختیاری",
    "فارس",
    "گیلان",
    "گلستان",
    "همدان",
    "هرمزگان",
    "کرمان",
    "کرمانشاه",
    "خراسان جنوبی",
    "خراسان رضوی",
    "خراسان شمالی",
    "خوزستان",
    "كهگیلویه و بویراحمد",
    "كردستان",
    "لرستان",
    "مركزی",
    "مازندران",
    "قزوین",
    "قم",
    "سمنان",
    "سیستان و بلوچستان",
    "آذربایجان شرقی",
    "آذربایجان غربی",
    "یزد",
    "زنجان",
  ];

  const ENTRY_ENUM = {
    0: "روزانه",
    1: "نوبت دوم",
    2: "مشترک",
    3: "غیرانتفاعی",
    4: "پیام نور",
    5: "شهريه پرداز",
    6: "پرديس خودگردان",
    7: "سیل زدگان",
    8: "مخصوص داوطلبان بومی شهرستانهای جنوب استان کرمان",
    9: "مخصوص داوطلبان شهرستان بشاگرد",
    10: "صرفا سوابق تحصیلی وزارت بهداشت",
    11: "مناطق محروم",
    12: "مجازی",
    13: "تعهدی وزارت بهداشت",
    14: "تعهدی پزشکی- شورای عالی انقلاب",
    15: "آزاد تمام وقت",
    16: "خودگردان آزاد",
    17: "بورسیه دانش",
    18: "بورسیه صنعتی",
    19: "بورسیه سایپا",
    20: "بورسیه انرژی اتمی",
    21: "تربیت مدرس"
  };

  const formatEntries = (list, type) => {
    return list.map((index, number) => ({
      id: type === "با آزمون" ? index + 1 : index + 20,
      type: type,
      name: ENTRY_ENUM[list[number]],
      selected: false,
    }));
  };

  const getUniqueEntries = (obj) => {
    const recordList = obj["5"] ? [...obj["5"]] : [];
    const examEntries = [];
    examEntries.push(obj["0"] ? [...obj["0"]] : []);
    examEntries.push(obj["1"] ? [...obj["1"]] : []);
    examEntries.push(obj["2"] ? [...obj["2"]] : []);
    examEntries.push(obj["3"] ? [...obj["3"]] : []);
    examEntries.push(obj["4"] ? [...obj["4"]] : []);

    const flattenExamEntries = examEntries.flat();
    const mixedEntries = flattenExamEntries.concat(recordList);
    const flattenMixedEntries = mixedEntries.flat();
    const uniqueMixedEntries = [...new Set(flattenMixedEntries)];
    const uniqueEntries = [...new Set(flattenExamEntries)];

    const finalExamEntry = formatEntries(uniqueMixedEntries, "با آزمون");
    const finalRecordEntry = formatEntries(recordList, "با سوابق");

    return { finalExamEntry, finalRecordEntry };
  };

  const { accountType } = useAuth();

  const initialListEndpoint =
    accountType === "0" ? "initial_list/" : "azad/initial_list/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://youtabteam.ir/api/${initialListEndpoint}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const majorNames = response.data.major_names;
        const formattedMajors = formatList(majorNames);

        const formattedProvinces = formatList(provinces);

        const entriesObj = response.data.entry_types;
        const formattedEntries = getUniqueEntries(entriesObj);

        localStorage.setItem("allMajors", JSON.stringify(formattedMajors));
        localStorage.setItem(
          "allProvinces",
          JSON.stringify(formattedProvinces)
        );
        localStorage.setItem(
          "allEntries",
          JSON.stringify([...formattedEntries.finalExamEntry])
        );

        setAllMajors(JSON.parse(localStorage.getItem("allMajors")));
        setAllProvinces(JSON.parse(localStorage.getItem("allProvinces")));
        setAllEntries(JSON.parse(localStorage.getItem("allEntries")));
      } catch (error) {
        console.error("Error fetching initial list:", error);
      }
    };

    if (!allMajors.length && !allProvinces.length && !allEntries.length) {
      fetchData();
    } else {
    }
  }, [setAllMajors, setAllProvinces, setAllEntries, initialListEndpoint]);

  useEffect(() => {
    setFirstLevelDone(true);
    setSecondLevelDone(true);
    setThirdLevelDone(false);
    setFourthLevelDone(false);
  }, [
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
  ]);

  const formatList = (list) => {
    return list.map((name, index) => ({
      id: index + 1,
      name: name,
      selected: false,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <Priorities />
      <Provinces />
      <Duration />
    </div>
  );
};

export default Content;
