import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfoProvinceModal from './MoreInfoProvinceModal';

const MoreInfoProvincePortal = ({ isOpen, onClose, province }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <MoreInfoProvinceModal onClose={onClose} province={province}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default MoreInfoProvincePortal;