import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useMatrix } from '../matrix/MatrixContext';

const CartChinContext = createContext();

export const CartChinProvider = ({ children }) => {

    const { orders, wholeData } = useMatrix();

    const wholeList = useMemo(() => {
        return orders.reduce((acc, order) => {
            const list = wholeData[order.id];
            if (list) {
                const updatedList = list.map(item => ({
                    ...item,
                    isSelected: !order.isDeleted
                }));
                return [...acc, ...updatedList];
            }
            return acc;
        }, []);
    }, [orders, wholeData]);

    useEffect(() => {
        setItems(wholeList);
    }, [wholeList]);

    const [items, setItems] = useState(() => {
        const storedItems = localStorage.getItem('cartChinItems');
        return storedItems ? JSON.parse(storedItems) : wholeList;
    });

    useEffect(() => {
        localStorage.setItem('cartChinItems', JSON.stringify(items));
    }, [items]);

    useEffect(() => {
        setItems(wholeList);
    }, [wholeList]);

const handleDragStart = (e, index) => {
e.dataTransfer.setData("index", index);
};

const handleDragOver = (e) => {
e.preventDefault();
};

const handleDrop = (e, newIndex) => {
e.preventDefault();
const oldIndex = e.dataTransfer.getData("index");
const newItems = [...items];
const [draggedItem] = newItems.splice(oldIndex, 1);
newItems.splice(newIndex, 0, draggedItem);
setItems(newItems);
};

const handlePriorityChange = (itemId, newPriority) => {
setItems(prevItems => {
const updatedItems = [...prevItems];

const itemIndex = updatedItems.findIndex(item => item.id === itemId);

if (itemIndex !== -1) {
    const [removedItem] = updatedItems.splice(itemIndex, 1);

    let newIndex = newPriority === 1 ? 0 : newPriority - 1;
    
    updatedItems.splice(newIndex, 0, removedItem);
}
    return updatedItems;
});
};

    return (
        <CartChinContext.Provider
            value={{ items, setItems, handlePriorityChange, handleDragStart, handleDragOver, handleDrop }}
        >
            {children}
        </CartChinContext.Provider>
    );
};

export const useCartChin = () => {
    return useContext(CartChinContext);
};
