import axios from "axios";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import Header from "../home/header/Header";
import Headline from "../konkoor_info/Headline";
import Content from "./Content";
import ControlButtons3Col from "./ControlButtons3Col";
import styles from "./MajorsAndProvinces.module.css";
import { useMajors } from "./MajorsContext";
import { useProvinces } from "./ProvincesContext";

const MajorsAndProvinces = () => {
  const { updateMajorSelection, updateEntrySelection } = useMajors();
  const { updateProvinceSelection, selectedProvinces } = useProvinces();
  const { selectedMajors, selectedEntries } = useMajors();

  const [prevSelectedMajor, setPreSelectedMajor] = useState([]);
  const [prevSelectedProvince, setPreSelectedProvince] = useState([]);
  const [prevSelectedEntry, setPreSelectedEntry] = useState([]);

  useEffect(() => {
    // Fetch data on mount
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://youtabteam.ir/api/v1/student-priority-tmp/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const selectedEntries = response?.data?.priority_data?.selectedEntries;
        const selectedMajors = response?.data?.priority_data?.selectedMajors;
        const selectedProvinces =
          response?.data?.priority_data?.selectedProvinces;

        // Check if selection_data and priority_data exist
        if (selectedMajors) {
          setPreSelectedMajor(selectedMajors);
          selectedMajors.map((item) => updateMajorSelection(item.id, true));
        }

        if (selectedEntries) {
          setPreSelectedEntry(selectedEntries);
          selectedEntries.map((item) => updateEntrySelection(item.id, true));
        }

        if (selectedProvinces) {
          setPreSelectedProvince(selectedProvinces);
          selectedProvinces.map((item) =>
            updateProvinceSelection(item.id, true)
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const saveData = async () => {
    try {
      const token = localStorage.getItem("token");
      const isEqualProvince = _.isEqual(
        selectedProvinces,
        prevSelectedProvince
      );
      const isEqualMajor = _.isEqual(selectedMajors, prevSelectedMajor);
      const isEqualEntry = _.isEqual(selectedEntries, prevSelectedEntry);
      const isChanged = isEqualEntry && isEqualMajor && isEqualProvince;

      // Access the context for orders (priorityData) and wholeData (selectionData)
      const payload = {
        priority_data: {
          selectedProvinces,
          selectedMajors,
          selectedEntries,
        },
        selection_data: !isChanged ? null : undefined,
      };

      const response = await axios.post(
        "https://youtabteam.ir/api/v1/student-priority-tmp/",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <Headline title="یوچویس" />
      <Content />
      <ControlButtons3Col saveData={saveData} />
    </div>
  );
};

export default MajorsAndProvinces;
