import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdMoreHoriz } from "react-icons/md";
import styles from './ProvinceItem.module.css';
import MoreInfoProvincePortal from "./MoreInfoProvincePortal";
import { useProvinces } from "../ProvincesContext";

const ProvinceItem = ({ province, index }) => {

    const { removeProvince } = useProvinces();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleDelete = () => {
        removeProvince(province.id);
    };

    return (
        <li className={styles.province}>
            {`${index + 1}. ${province.name}`}
            <RxCross2 className={styles.delete} onClick={handleDelete} />
            <MdMoreHoriz className={styles.more} onClick={openModal}/>
            <MoreInfoProvincePortal isOpen={isModalOpen} onClose={closeModal} province={province}/>
        </li>
    );
};

export default ProvinceItem;