import React, { useRef, useEffect, useState } from "react";
import styles from './SelectCourseModal.module.css';
import { CiSearch } from "react-icons/ci";
import { BsChevronExpand } from "react-icons/bs";
import { useMajors } from "../MajorsContext";

const SelectCourseModal = ({ onClose }) => {
    const { allMajors, updateMajorSelection, addSelectedMajors } = useMajors();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredMajors, setFilteredMajors] = useState(allMajors);

    let lengthOfMajors = allMajors.length;

    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        const filtered = allMajors.filter(major =>
            major.name.includes(searchQuery)
        );
        setFilteredMajors(filtered);
    }, [searchQuery, allMajors]);

    const handleCheckboxChange = (id) => {
        const updatedMajors = filteredMajors.map(major =>
            major.id === id ? { ...major, selected: !major.selected } : major
        );
        setFilteredMajors(updatedMajors);
        updateMajorSelection(id, !filteredMajors.find(major => major.id === id).selected);
    };


    const addMajors = () => {
        addSelectedMajors();
        onClose();
    }

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}>
                <title className={styles.title}>اضافه کردن رشته جدید</title>
                <input
                    className={styles.search_box}
                    type="text"
                    placeholder='جستجوی رشته های تحصیلی'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CiSearch className={styles.search_icon}/>
                <div className={styles.majors_list}>
                    <div className={styles.major_name}>نام رشته
                        <BsChevronExpand className={styles.major_icon}/>
                    </div>
                    <ul className={styles.list}
                    style={{width: lengthOfMajors>8 ? '105%' : '100%'}}>
                        {filteredMajors.map((item, index) => (
                            <li key={index} className={styles.major}>
                                <input
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                <label>{item.name}</label>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className={styles.add} onClick={addMajors}>اضافه کردن</button>
                <button className={styles.cancel} onClick={onClose}>انصراف</button>
            </div>
        </div>
    );
}

export default SelectCourseModal;
