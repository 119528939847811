import React from "react";
import styles from './Intro.module.css';
import YoutabLogo from '../images/youtab-logo.png';
import AnimationComponent from "./AnimationComponent";
import animationData from "./animationData.json";
import { useNavigate } from "react-router-dom";

const Intro = () => {
    const navigate = useNavigate();

    return(
        <>
        <div className={styles.animation}>
            <AnimationComponent animationData={animationData} />
        </div>
        <div className={styles.wrapper}>
            <img className={styles.logo} src={YoutabLogo} alt="pic"/>
            <title className={styles.title}>کامل‌ترین سامانه انتخاب رشته کشور</title>
            <p className={styles.text}>مجموعه یوتاب در تلاش است
                با تلفیق ساده ترین معانی انتخاب رشته کنکور سراسری
                <br/>
            با تکنولوژی روز دنیا، ابزاری را در اختیار مجموعه ها، مشاورین و 
            داوطلبان قرار دهد که بتوان به کمک آن، فرایند شکل دهی
             یکی از مهم ترین و تعیین کننده ترین دوران زندگی هر شخصی را تا حد توان بهبود بخشید.</p>
            <button 
            onClick={() => navigate('/start')}
            className={styles.start}>شروع کنید</button>
        </div>
        </>
    )
}

export default Intro;