import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfoUnitModal from './MoreInfoUnitModal';

const MoreInfoUnitPortal = ({ isOpen, onClose, units, major }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <MoreInfoUnitModal onClose={onClose} units={units} major={major}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default MoreInfoUnitPortal;