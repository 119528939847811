import React from "react";
import styles from './MapInfo.module.css';
import { useMap } from "./MapContext";

const MapInfo = () => {

    const { data } = useMap();

    const GROUP_ENUM = {
        0: ['دوره‌ها:', 'روزانه، نوبت دوم، پردیس خودگردان، شهریه پرداز، مناطق محروم، سیل زدگان، غیرانتفاعی و پیام‌نور',
        'دانشگاه‌های گرید A تهران:', 'دانشگاه تهران', 'دانشگاه صنعتی شریف', 'دانشگاه صنعتی امیرکبیر', 
        'دانشگاه علوم پزشکی ایران', 'دانشگاه علوم پزشکی شهید بهشتی', 'دانشگاه علوم قضایی و خدمات اداری دادگستری', 
        'دانشگاه علوم پزشکی بقیة الله (عج)', 'دانشگاه‌های گرید B تهران:', 'دانشگاه شهید بهشتی', 
        'دانشگاه علم و صنعت', 'دانشگاه خواجه نصیر الدین طوسی', 'دانشگاه‌های گرید C تهران:', 
        'دانشگاه خوارزمی', 'دانشگاه علامه طباطبایی', 'دانشگاه الزهرا (س) (ویژه خواهران)', 'دانشگاه‌های گرید D  تهران:',
        'دانشگاه اطلاعات و امنیت ملی امام باقر (ع)', 'دانشگاه صدا و سیمای جمهوری اسلامی ایران', 'مرکز آموزش عالی هوانوردی و فرودگاهی کشور', 
        'آموزشکده فنی و حرفهای پسران شماره دو تهران - شهید شمسی پور', 'دانشکده فنی و حرفه ای پسران شماره یک تهران - انقلاب اسلامی',
        'دانشکده فنی و حرفه ای دختران تهران - دکتر شریعتی', 'دانشکده فنی و حرفه ای دختران تهران - دکتر شریعتی', 
        'دانشگاه جامع امام حسین (ع ) - تهران', 'دانشگاه صنعت نفت (محل تحصیل واحد تهران)',
        'دانشگاه علوم توانبخشی و سلامت اجتماعی - تهران', 'دانشگاه علوم و معارف قرآن کریم - سازمان اوقاف و امور خیریه',
        'دانشگاه غیردولتی شهید مطهری - تهران', 'آموزشکده فنی نقشه برداری - سازمان جغرافیایی نیروهای مسلح',
        'دانشگاه‌های غیرانتفاعی', 'دانشگاه‌های پیام‌نور'],
        1: ['دوره‌ها:', 'روزانه، نوبت دوم، مناطق محروم، سیل زدگان، غیرانتفاعی و پیام‌نور', 
        'دانشگاه‌های گرید A تهران:', ' دانشگاه تهران', 'دانشگاه شهید بهشتی', 'دانشگاه علامه طباطبایی',
    'دانشگاه علوم قضایی و خدمات اداری دادگستری', 'دانشگاه‌های گرید B تهران:', ' دانشگاه خوارزمی', 'دانشگاه الزهرا (س) (ویژه خواهران)', 'دانشگاه‌های گرید C تهران:', 
    'دانشگاه اطلاعات و امنیت ملی امام باقر (ع)', 'دانشگاه صدا و سیمای جمهوری اسلامی ایران',
    'دانشگاه علوم توانبخشی و سلامت اجتماعی', 'دانشگاه جامع امام حسین (ع )', 'دانشگاه صنعت نفت (محل تحصیل واحد تهران)',
    ' دانشگاه علوم و معارف قرآن کریم - سازمان اوقاف و امور خیریه', 'دانشگاه غیردولتی شهید مطهری',
    ' آموزشکده فنی و حرفهای پسران شماره دو تهران - شهید شمسی پور', 'دانشکده فنی و حرفه ای دختران تهران - دکتر شریعتی', 
    'دانشگاه‌های گرید D  تهران:', 'دانشگاه‌های غیر انتفاعی', ' دانشگاه‌های پیام نور'],
        2: ['دوره‌ها:', 'روزانه، نوبت دوم، شهریه پرداز، پردیس خودگردان، آزاد تمام وقت، خودگردان آزاد، مناطق محروم، سیل زدگان، تعهدی وزارت بهداشت، تعهدی پزشکی – شورای عالی انقلاب فرهنگی، غیرانتفاعی و پیام‌نور',
        'دانشگاه‌های گرید A تهران:', 'دانشگاه تهران', 'دانشگاه علوم پزشکی تهران', 'دانشگاه شهید بهشتی',
        'دانشگاه علوم پزشکی شهید بهشتی', 'دانشگاه علوم پزشکی ایران', 'دانشگاه صنعتی شریف', 'علامه طباطبایی',
        'دانشگاه علوم قضایی و خدمات اداری دادگستری', 'دانشگاه‌های گرید B تهران:',
        'دانشگاه شاهد (رشته‌های پزشکی)', 'دانشگاه علوم پزشکی ارتش جمهوری اسلامی ایران', 'دانشگاه علوم پزشکی بقیة الله (عج)', 
        'دانشگاه‌های گرید C تهران:', 'دانشگاه علوم توانبخشی و سلامت اجتماعی تهران( رشته های پزشکی)',
        'موسسه آموزش عالی علمی کاربردی هلال ایران', ' دانشگاه آزاد اسلامی استان تهران واحد پزشکی تهران',
        'دانشگاه آزاد اسلامی استان تهران واحد علوم و تحقیقات', 'دانشگاه‌های گرید D  تهران:', 'دانشگاه اطلاعات و امنیت ملی امام باقر (ع',
        'دانشگاه الزهرا (س) (ویژه خواهران) - تهران', 'دانشگاه جامع امام حسین (ع ) - تهران', 'دانشگاه خوارزمی', 
        'دانشگاه صنعت نفت (محل تحصیل واحد تهران)', 'دانشگاه صنعتی خواجه نصیر الدین طوسی - تهران',
        'دانشگاه علوم توانبخشی و سلامت اجتماعی - تهران', 'دانشگاه علوم و معارف قرآن کریم - سازمان اوقاف و امور خیریه',
         'دانشگاه غیردولتی شهید مطهری - تهران', 'آموزشکده فنی و حرفهای پسران شماره دو تهران - شهید شمسی پور', 
         'دانشکده فنی و حرفه ای دختران تهران - دکتر شریعتی', 'دانشگاه آزاد اسلامی استان تهران   واحد علوم و تحقیقات', 
        'دانشگاه‌های غیرانتفاعی', 'دانشگاه‌های پیام‌نور'],
        }

    return(
        <div className={styles.wrapper}>
            <ul className={styles.items}>
                {
                    data.study_group !== undefined && GROUP_ENUM[data.study_group].map((item, index) => 
                    {
                        return <li key={index} className={styles.item}
                        style={{fontWeight: (item === 'دوره‌ها:' || item === 'دانشگاه‌های گرید A تهران:' || item === 'دانشگاه‌های گرید B تهران:' || item === 'دانشگاه‌های گرید C تهران:' || item === 'دانشگاه‌های گرید D  تهران:') ? '900' : '300',
                        fontSize: (item === 'دوره‌ها:' || item === 'دانشگاه‌های گرید A تهران:' || item === 'دانشگاه‌های گرید B تهران:' || item === 'دانشگاه‌های گرید C تهران:' || item === 'دانشگاه‌های گرید D  تهران:') ? '.85vw' : '.75vw'}}>
                            {item}
                            </li>
                    }) 
                }
            </ul>
        </div>
    )
}

export default MapInfo;