import React, { useRef, useEffect } from "react";
import styles from './MoreInfoUnitModal.module.css';

const MoreInfoUnitModal = ({ onClose, units, major }) => {


    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const unitArray = typeof units === 'string' ? units.split('،').map(unit => unit.trim()) : [];

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}> 
            <div className={styles.title}>:واحدهای دانشگاهی {major.name}</div>
            {units.length > 0 ?
                <ul className={styles.similar_majors}>
                    {unitArray.map((unit, index) => (
                        <li key={index} className={styles.simi_major}>{unit}</li>
                    ))}
                </ul>
                :
                <div className={styles.not_found}>واحد دانشگاهی یافت نشد!</div>
            }
            </div>
        </div>
    ); 
}

export default MoreInfoUnitModal;