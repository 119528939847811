import React, { useEffect } from "react";
import styles from './ControlButtonsMap.module.css';
import { useNavigate } from "react-router-dom";

const ControlButtonsMap = () => {
    const navigate = useNavigate();

    const nextStage = () => {
        navigate('/majors-and-provinces');
    }

    const preStage = () => {
        navigate('/course-selection');
    }

    const cancelFunction = () => {
    }

            return(
            <div className={styles.wrapper}>
            <button className={styles.previous} onClick={preStage}>مرحله قبلی</button>
            <button className={styles.next} onClick={nextStage}>مرحله بعدی</button>
            <button className={styles.cancel} onClick = {cancelFunction}>انصراف</button>
        </div>
        )
}

export default ControlButtonsMap;