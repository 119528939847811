import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthContext";
import styles from "./ControlButtons3Col.module.css";
import { useMajors } from "./MajorsContext";
import Next3ColumnModal from "./Next3ColumnModal";
import { useProvinces } from "./ProvincesContext";

const ControlButtons3Col = ({ saveData }) => {
  const { selectedMajors, selectedEntries, allMajors, allEntries } =
    useMajors();
  const { selectedProvinces, allProvinces } = useProvinces();
  const navigate = useNavigate();
  const [notCompleteError, setNotCompleteError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { accountType } = useAuth();

  const isGlobal = accountType === "0";

  const preStage = () => {
    if (isGlobal) {
      navigate("/map");
    } else {
      navigate("/course-selection");
    }
  };

  const cancelFunction = () => {
    localStorage.removeItem("selected-majors");
    localStorage.removeItem("selected-provinces");
    localStorage.removeItem("selected-entries");
  };

  const openModal = () => {
    if (
      selectedMajors.length === 0 ||
      selectedProvinces.length === 0 ||
      selectedEntries.length === 0
    ) {
      setNotCompleteError(true);
    } else {
      setNotCompleteError(false);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    saveData();
    navigate("/matrix");
    closeModal();
  };

  useEffect(() => {
    localStorage.setItem("selected-majors", JSON.stringify(selectedMajors));
  }, [selectedMajors, allMajors]);

  useEffect(() => {
    localStorage.setItem(
      "selected-provinces",
      JSON.stringify(selectedProvinces)
    );
  }, [selectedProvinces, allProvinces]);

  useEffect(() => {
    localStorage.setItem("selected-entries", JSON.stringify(selectedEntries));
  }, [selectedEntries, allEntries]);

  return (
    <div className={styles.wrapper}>
      <button className={styles.previous} onClick={preStage}>
        مرحله قبلی
      </button>
      <button className={styles.next} onClick={openModal}>
        مرحله بعدی
      </button>
      <button className={styles.cancel} onClick={cancelFunction}>
        انصراف
      </button>
      <Next3ColumnModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={confirmDelete}
      />
      {notCompleteError && (
        <div className={styles.not_complete}>
          وارد کردن اطلاعات هر سه ستون اجباری است!
        </div>
      )}
    </div>
  );
};

export default ControlButtons3Col;
