import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useAuth } from "../login/AuthContext";
import styles from "./Headline.module.css";
import { useHeadline } from "./HeadlineContext";

const Headline = ({ title }) => {
  const {
    firstLevelDone,
    secondLevelDone,
    thirdLevelDone,
    fourthLevelDone,
    fifthLevelDone,
  } = useHeadline();

  const { accountType } = useAuth();
  const isGlobal = accountType === "0";

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.time_line}>
        {/* FIRST STEP */}
        <div className={styles.step_continuer}>
          <div
            className={styles.circle1}
            style={{ backgroundColor: "#232754" }}
          >
            {firstLevelDone && (
              <IoCheckmarkSharp className={styles.uform_check} />
            )}
          </div>
          <div className={styles.u_form}>یوفرم</div>
        </div>
        <div
          className={styles.line1}
          style={{ backgroundColor: firstLevelDone ? "#232754" : "#60D7FF" }}
        ></div>

        {/* SECOND STEP */}
        {isGlobal && (
          <>
            <div className={styles.step_continuer}>
              <div
                className={styles.circle2}
                style={{
                  backgroundColor: firstLevelDone ? "#232754" : "#60D7FF",
                }}
              >
                {secondLevelDone && (
                  <IoCheckmarkSharp className={styles.umap_check} />
                )}
              </div>

              <div className={styles.u_map}>یومپ</div>
            </div>
            <div
              className={styles.line2}
              style={{
                backgroundColor: secondLevelDone ? "#232754" : "#60D7FF",
              }}
            ></div>
          </>
        )}

        {/* THIRD STEP */}
        <div className={styles.step_continuer}>
          <div
            className={styles.circle3}
            style={{ backgroundColor: secondLevelDone ? "#232754" : "#60D7FF" }}
          >
            {thirdLevelDone && (
              <IoCheckmarkSharp className={styles.uchoice_check} />
            )}
          </div>
          <div className={styles.u_choice}>یوچویس</div>
        </div>

        <div
          className={styles.line3}
          style={{ backgroundColor: thirdLevelDone ? "#232754" : "#60D7FF" }}
        ></div>

        {/* FOURTH STEP */}
        <div className={styles.step_continuer}>
          <div
            className={styles.circle4}
            style={{ backgroundColor: thirdLevelDone ? "#232754" : "#60D7FF" }}
          >
            {fourthLevelDone && (
              <IoCheckmarkSharp className={styles.utrix_check} />
            )}
          </div>
          <div className={styles.utrix}>یوتریکس</div>
        </div>
        <div
          className={styles.line4}
          style={{ backgroundColor: fourthLevelDone ? "#232754" : "#60D7FF" }}
        ></div>

        {/* FIFTH STEP */}
        <div className={styles.step_continuer}>
          <div
            className={styles.circle5}
            style={{ backgroundColor: fourthLevelDone ? "#232754" : "#60D7FF" }}
          ></div>
          <div className={styles.ulist}>یولیست</div>
          {fifthLevelDone && (
            <IoCheckmarkSharp className={styles.ulist_check} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Headline;
