import React, { useState } from "react";
import Majors from "./Majors";
import styles from './Priorities.module.css';
import SelectCoursePortal from "./SelectCoursePortal";

const Priorities = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>تعیین اولویت های رشته تحصیلی</div>
            <Majors />
            <button 
            className={styles.add_major_button}
            onClick={openModal}
            >اضافه کردن رشته جدید +</button>
            <SelectCoursePortal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
    )
}

export default Priorities;