import React, { useRef, useState, useEffect } from "react";
import styles from "./ChooseMajDurModal.module.css";
import { useMatrix } from "./MatrixContext";
import { useAuth } from "../login/AuthContext";

const ChooseMajDurModal = ({ onClose }) => {
  const [chosenProvince, setChosenProvince] = useState("");
  const [chosenEntry, setChosenEntry] = useState("");
  const [isCenter, setIsCenter] = useState("مرکز");
  const [duplicateError, setDuplicateError] = useState(false);
  const [notCompleteError, setNotCompleteError] = useState(false);

  const { entProvList, addNewEntProvItem } = useMatrix();

  const modalRef = useRef(null);

  const { accountType } = useAuth();

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  const divideTehran = (provinces) => {
    const dividedTehran =
      accountType === "1"
        ? ["تهران حومه", "تهران مرکز"]
        : [
            "تهران A",
            "تهران B",
            "تهران C",
            "تهران D",
            // "تهران PA",
            "تهران حومه",
            "تهران مرکز",
          ];

    const tehranIndex = provinces.findIndex(
      (province) => province.name === "تهران"
    );

    if (tehranIndex !== -1) {
      provinces.splice(
        tehranIndex,
        1,
        ...dividedTehran.map((name, index) => ({ id: `tehran-${index}`, name }))
      );
    }

    return provinces;
  };

  useEffect(() => {
    if (chosenProvince === "تهران حومه" || chosenProvince === "تهران مرکز") {
      setIsCenter("حومه");
    }
  }, [chosenProvince]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const allProvinces = JSON.parse(localStorage.getItem("selected-provinces"));
  const allEntries = JSON.parse(localStorage.getItem("selected-entries"));

  const handleAddEntProv = () => {
    const isDuplicate = entProvList.some(
      (item) =>
        item.province === chosenProvince &&
        item.entry === chosenEntry &&
        item.isCenter === (isCenter === "مرکز" ? 1 : 0)
    );

    if (isDuplicate) {
      setDuplicateError(true);
    } else if (
      chosenEntry === "" ||
      chosenProvince === "" ||
      chosenEntry === "انتخاب کنید" ||
      chosenProvince === "انتخاب کنید" ||
      isCenter === "انتخاب کنید"
    ) {
      setNotCompleteError(true);
      setDuplicateError(false);
    } else {
      setDuplicateError(false);
      setNotCompleteError(false);
      addNewEntProvItem({
        entry: chosenEntry,
        province: chosenProvince,
        isCenter: isCenter === "مرکز" ? 1 : 0,
      });
      onClose();
    }
  };
  const updatedProvinces = divideTehran(allProvinces);

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal} ref={modalRef}>
        {notCompleteError && (
          <div className={styles.error}> اطلاعات واردشده کامل نمی باشد! </div>
        )}
        {duplicateError && (
          <div className={styles.error}>
            این دوره و استان قبلا انتخاب شده اند!
          </div>
        )}
        <title className={styles.title}>انتخاب استان/دوره</title>
        <label className={styles.prov_label}>استان:</label>
        <select
          className={styles.prov_dropdown}
          value={chosenProvince}
          onChange={(e) => setChosenProvince(e.target.value)}
        >
          <option>انتخاب کنید</option>
          {updatedProvinces.map((province) => (
            <option key={province.id} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
        <label className={styles.dur_label}>دوره:</label>
        <select
          className={styles.dur_dropdown}
          value={chosenEntry}
          onChange={(e) => setChosenEntry(e.target.value)}
        >
          <option>انتخاب کنید</option>
          {allEntries.map((entry) => (
            <option key={entry.id} value={entry.name}>
              {entry.name}
            </option>
          ))}
        </select>
        <label className={styles.center_label}>حومه/مرکز:</label>
        <select
          className={styles.center_dropdown}
          value={chosenProvince.includes("تهران") ? "انتخاب کنید" : isCenter}
          onChange={(e) => setIsCenter(e.target.value)}
          style={{
            pointerEvents: chosenProvince.includes("تهران") ? "none" : "all",
            opacity: chosenProvince.includes("تهران") ? ".3" : "1",
          }}
        >
          <option>انتخاب کنید</option>
          <option>مرکز</option>
          <option>حومه</option>
        </select>
        <button className={styles.add} onClick={handleAddEntProv}>
          انتخاب
        </button>
        <button onClick={onClose} className={styles.cancel}>
          انصراف
        </button>
      </div>
    </div>
  );
};

export default ChooseMajDurModal;
