import React from "react";
import styles from './Footer.module.css';
import FooterImg from '../images/Footer.svg';
import FooterLogo from '../images/FooterLogo.svg';
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";


const Footer = () => {
    return(
        <div className={styles.wrapper}>
            <img className={styles.footer_img} src={FooterImg}/>
            <div className={styles.text}>کامل‌ترین سامانه انتخاب رشته کشور</div>
            <div className={styles.address}>کرج، گلشهرویلای شرقی، خیابان ابن سینا، خیابان ابوریحان شرقی، پلاک 63، واحد 3</div>
            <img className={styles.footer_logo} src={FooterLogo}/>

            <div className={styles.telegram}>
                <FaTelegramPlane />
            </div>
            <a href="https://www.instagram.com/youtab_team?igsh=MXJveWp3dnRjc3dqbg%3D%3D&utm_source=qr" className={styles.instagram} target="_blank" rel="noopener noreferrer">
                <IoLogoInstagram />
            </a>
            <div className={styles.linkedin}>
                <FaLinkedinIn />
            </div>
            <div className={styles.team}>youtabteam</div>
        </div>
    )
}

export default Footer;