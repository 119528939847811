import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../home/header/Header";
import Headline from "../konkoor_info/Headline";
import { useHeadline } from "../konkoor_info/HeadlineContext";
import ControlButtonsMat from "./ControlButtonsMat";
import styles from "./Matrix.module.css";
import MatrixContent from "./MatrixContent";
import { useMatrix } from "./MatrixContext"; // Import useMatrix to access and update context
import size from "lodash/size";

const Matrix = () => {
  const {
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
  } = useHeadline();

  const {
    matrixData,
    orders,
    wholeData,
    setOrders,
    setWithoutChoice,
    setWholeData,
    setEntProvList,
    setMatrixData,
    entProvList,
    withoutChoice,
  } = useMatrix();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data on mount
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://youtabteam.ir/api/v1/student-priority-tmp/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const wholeData = response?.data?.selection_data?.wholeData;
        const entProvList = response?.data?.selection_data?.entProvList;
        const orders = response?.data.selection_data?.orders;
        const withoutChoice = response?.data?.selection_data?.withoutChoice;
        const matrixData = response?.data?.selection_data?.matrixData;

        if (orders) {
          localStorage.setItem("orders", JSON.stringify(orders));
          setOrders(orders);
        }
        if (withoutChoice) {
          localStorage.setItem("withoutChoice", JSON.stringify(withoutChoice));
          setWithoutChoice(withoutChoice);
        }
        if (wholeData) {
          localStorage.setItem("wholeData", JSON.stringify(wholeData));
          setWholeData(wholeData);
        }
        if (entProvList) {
          localStorage.setItem("ent-prov-list", JSON.stringify(entProvList));
          setEntProvList(entProvList);
        }
        if (matrixData) {
          // Calculate the size of the matrixData object
          const matrixDataSize = size(JSON.stringify(matrixData)) * 2; // Size in bytes
          const maxLocalStorageSize = 5 * 1024 * 1024; // 5MB in bytes

          if (matrixDataSize < maxLocalStorageSize) {
            localStorage.setItem("matrix-data", JSON.stringify(matrixData));
            setMatrixData(matrixData);
          } else {
            console.warn(
              "Matrix data exceeds the 5MB localStorage limit and will not be stored."
            );
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    setFirstLevelDone(true);
    setSecondLevelDone(true);
    setThirdLevelDone(true);
    setFourthLevelDone(false);
  }, [
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
    setWholeData,
    setOrders,
    setMatrixData,
  ]);

  const saveData = async (isEmpty = false) => {
    try {
      const token = localStorage.getItem("token");
      // Access the context for orders (priorityData) and wholeData (selectionData)
      const payload = {
        selection_data: isEmpty
          ? null
          : {
              wholeData,
              orders,
              matrixData,
              entProvList,
              withoutChoice,
            },
      };
      const response = await axios.post(
        "https://youtabteam.ir/api/v1/student-priority-tmp/",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <Headline title="یوتریکس" />
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          در حال بارگزاری
        </div>
      ) : (
        <>
          <MatrixContent />
          <ControlButtonsMat saveData={saveData} />
        </>
      )}
    </div>
  );
};

export default Matrix;
