import React, { createContext, useContext, useState, useEffect } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {

    const [data, setData] = useState({});
    const [selectedProvince, setSelectedProvince] = useState(0);

    return (
        <MapContext.Provider value={{ data, setData, selectedProvince, setSelectedProvince }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => {
    return useContext(MapContext);
};