import React from "react";
import styles from './MapImageInfo.module.css';
import { useMap } from "./MapContext";

const MapImageInfo = () => {
    const { setSelectedProvince } = useMap();

    const PROVINCE_ENUM = {
        "تهران": 0,
        "البرز": 5,
        "اردبیل": 6,
        "اصفهان": 7,
        "ایلام": 8,
        "بوشهر": 9,
        "چهارمحال و بختیاری": 10,
        "فارس": 11,
        "گیلان": 12,
        "گلستان": 13,
        "همدان": 14,
        "هرمزگان": 15,
        "کرمان": 16,
        "کرمانشاه": 17,
        "خراسان جنوبی": 18,
        "خراسان رضوی": 19,
        "خراسان شمالی": 20,
        "خوزستان": 21,
        "كهگیلویه و بویراحمد": 22,
        "كردستان": 23,
        "لرستان": 24,
        "مركزی": 25,
        "مازندران": 26,
        "قزوین": 27,
        "قم": 28,
        "سمنان": 29,
        "سیستان و بلوچستان": 30,
        "آذربایجان شرقی": 31,
        "آذربایجان غربی": 32,
        "یزد": 33,
        "زنجان": 34
        };

        const province_list = [
            "تهران",
            "البرز",
            "اردبیل",
            "اصفهان",
            "ایلام",
            "بوشهر",
            "چهارمحال و بختیاری",
            "فارس",
            "گیلان",
            "گلستان",
            "همدان",
            "هرمزگان",
            "کرمان",
            "کرمانشاه",
            "خراسان جنوبی",
            "خراسان رضوی",
            "خراسان شمالی",
            "خوزستان",
            "كهگیلویه و بویراحمد",
            "كردستان",
            "لرستان",
            "مركزی",
            "مازندران",
            "قزوین",
            "قم",
            "سمنان",
            "سیستان و بلوچستان",
            "آذربایجان شرقی",
            "آذربایجان غربی",
            "یزد",
            "زنجان"
        ]
    const regionList = [
        {
            number: '1',
            color: '#8EECF5'
        },
        {
            number: '6',
            color: '#60D7FF'
        },
        {
            number: '2',
            color: '#94E79D'
        },
        {
            number: '7',
            color: '#85B7FF'
        },
        {
            number: '3',
            color: '#CFBAF0'
        },
        {
            number: '8',
            color: '#FDE4CF'
        },
        {
            number: '4',
            color: '#FFCFD2'
        },
        {
            number: '9',
            color: '#6FD3BE'
        },
        {
            number: '5',
            color: '#F1C0E8'
        },
    ];

    const listItems = regionList.map((region, index) => (
        <li key={index} className={styles.color}>
            <div className={styles.region}>ناحیه {region.number}</div>
            <div className={styles.region_color} style={{backgroundColor : region.color}}></div>
        </li>
    ));
    return(
        <div className={styles.wrapper}>
            <label className={styles.choose_label}>انتخاب استان:</label>
            <select className={styles.dropdown} onChange={(e)=> setSelectedProvince(PROVINCE_ENUM[e.target.value])}>
                {
                    province_list.map((province, index) => {
                        return <option key={index}>{province}</option>
                    })
                }
            </select>
            <div className={styles.map_guide}>
                <h4 className={styles.colors_title}>راهنمای نقشه</h4>
                <ul className={styles.map_colors}>
                    {listItems}
                </ul>
            </div>
        </div>
    )
}

export default MapImageInfo;