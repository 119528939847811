import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const navigate = useNavigate();

  const onSetAccountType = (accountType) => {
    if (accountType != null) {
      setAccountType(`${accountType}`);
    }
  };

  useEffect(() => {
    setAccountType(localStorage.getItem("account_type"));
  }, [localStorage.getItem("account_type")]);

  const login = async (username, password) => {
    setLoading(true);
    setHasError(false);
    try {
      const response = await axios.post("https://youtabteam.ir/api/token/", {
        username,
        password,
      });

      const response1 = await axios.get("https://youtabteam.ir/api/students/", {
        headers: { Authorization: `Bearer ${response.data.access}` },
      });

      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);

      onSetAccountType(response1.data.account_type);
      localStorage.setItem("account_type", response1.data.account_type);

      navigate("/course-selection");
    } catch (error) {
      setHasError(true);
    }
    setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setUser(null);
    localStorage.removeItem("allMajors");
    localStorage.removeItem("allProvinces");
    localStorage.removeItem("allEntries");
  };

  const getToken = () => localStorage.getItem("token");
  const getRefreshToken = () => localStorage.getItem("refreshToken");

  return (
    <AuthContext.Provider
      value={{
        hasError,
        loading,
        login,
        logout,
        getToken,
        getRefreshToken,
        user,
        setUser,
        accountType,
        onSetAccountType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
