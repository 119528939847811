import React, { createContext, useContext, useEffect, useState } from "react";

const MatrixContext = createContext();

export const MatrixProvider = ({ children }) => {
  const [orders, setOrders] = useState(() => {
    const savedOrders = localStorage.getItem("orders");
    return savedOrders ? JSON.parse(savedOrders) : [];
  });

  const [withoutChoice, setWithoutChoice] = useState(() => {
    const savedChoice = localStorage.getItem("withoutChoice");
    return savedChoice ? JSON.parse(savedChoice) : [];
  });

  const [wholeData, setWholeData] = useState(() => {
    const savedWholeData = localStorage.getItem("wholeData");
    return savedWholeData ? JSON.parse(savedWholeData) : {};
  });

  const [entProvList, setEntProvList] = useState(() => {
    const savedList = localStorage.getItem("ent-prov-list");
    return savedList ? JSON.parse(savedList) : [];
  });

  const [matrixData, setMatrixData] = useState(() => {
    const savedData = localStorage.getItem("matrix-data");
    return savedData ? JSON.parse(savedData) : [];
  });

  const addNewEntProvItem = (newItem) => {
    setEntProvList((prevState) => [...prevState, newItem]);
  };

  useEffect(() => {
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  useEffect(() => {
    localStorage.setItem("withoutChoice", JSON.stringify(withoutChoice));
  }, [withoutChoice]);

  useEffect(() => {
    localStorage.setItem("wholeData", JSON.stringify(wholeData));
  }, [wholeData]);

  useEffect(() => {
    localStorage.setItem("ent-prov-list", JSON.stringify(entProvList));
  }, [entProvList]);

  useEffect(() => {
    localStorage.setItem("matrix-data", JSON.stringify(matrixData));
  }, [matrixData]);

  return (
    <MatrixContext.Provider
      value={{
        entProvList,
        setEntProvList,
        addNewEntProvItem,
        matrixData,
        setMatrixData,
        orders,
        setOrders,
        wholeData,
        setWholeData,
        withoutChoice,
        setWithoutChoice,
      }}
    >
      {children}
    </MatrixContext.Provider>
  );
};

export const useMatrix = () => {
  return useContext(MatrixContext);
};
