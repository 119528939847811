import React from 'react';
import ReactDOM from 'react-dom';
import ChangePriorityModal from './ChangePriorityModal';

const ChangePriorityPortal = ({ isOpen, onClose, itemId, onPriorityChange, currentIndex, numOfItems }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <ChangePriorityModal onClose={onClose} itemId={itemId} onPriorityChange={onPriorityChange} currentIndex={currentIndex} numOfItems={numOfItems}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default ChangePriorityPortal;