import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCartChin } from "../cart-chin/CartChinContext";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmSaveModal from "./ConfirmSaveModal";
import styles from "./ControlButtonsMat.module.css";
import { useMatrix } from "./MatrixContext";
import NextMatrixModal from "./NextMatrixModal";

const ControlButtonsMat = ({ saveData }) => {
  const {
    setEntProvList,
    setMatrixData,
    setOrders,
    setWholeData,
    setWithoutChoice,
  } = useMatrix();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNextModalOpen, setIsNextModalOpen] = useState(false);
  const { items } = useCartChin();
  const selectedItems = items.filter((item) => item.isSelected);
  const navigate = useNavigate();

  const nextStage = () => {
    saveData(false);
    navigate("/cart-chin");
  };

  const preStage = () => {
    saveData(false);
    navigate("/majors-and-provinces");
  };

  const cancelMatrix = () => {
    localStorage.removeItem("matrix-data");
    localStorage.removeItem("ent-prov-list");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    setEntProvList([]);
    setMatrixData([]);
    setOrders([]);
    setWholeData({});
    setWithoutChoice([]);
    saveData(true);
    closeModal();
  };

  const openNextModal = () => {
    setIsNextModalOpen(true);
  };

  const closeNextModal = () => {
    setIsNextModalOpen(false);
  };

  const onSaveModal = () => {
    saveData(false);
    setIsSaveModalOpen(false);
  };

  const onCancelModal = () => {
    setIsSaveModalOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      {selectedItems.length > 250 && (
        <p className={styles.textError}>
          .رشته‌های انتخاب شده بیش از حد مجاز است
        </p>
      )}
      <button className={styles.previous} onClick={preStage}>
        مرحله قبلی
      </button>
      <button
        className={styles.next}
        onClick={openNextModal}
        disabled={selectedItems.length > 250}
      >
        مرحله بعدی
      </button>
      <button className={styles.cancel} onClick={cancelMatrix}>
        انصراف
      </button>

      <button className={styles.delete} onClick={openModal}>
        پاک کردن جدول
      </button>

      <button
        className={styles.success}
        onClick={() => setIsSaveModalOpen(true)}
      >
        ذخیره جدول
      </button>

      <ConfirmDelete
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={confirmDelete}
      />

      <ConfirmSaveModal
        isOpen={isSaveModalOpen}
        onRequestClose={onCancelModal}
        onConfirm={onSaveModal}
      />

      <NextMatrixModal
        isOpen={isNextModalOpen}
        onRequestClose={closeNextModal}
        onConfirm={nextStage}
      />
    </div>
  );
};

export default ControlButtonsMat;
