import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import YoutabLogo from "../../../images/youtab-logo.png";
import { useKonkoorContext } from "../../konkoor_info/KonkoorContext";
import { useAuth } from "../../login/AuthContext";
import { useMajors } from "../../majors_and_provinces/MajorsContext";
import { useProvinces } from "../../majors_and_provinces/ProvincesContext";
import { useMatrix } from "../../matrix/MatrixContext";
import styles from "./Header.module.css";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setAllProvinces } = useProvinces();
  const { setAllMajors, setAllEntries } = useMajors();
  const { logout, user } = useAuth();
  const { setFormData } = useKonkoorContext();
  const {
    setEntProvList,
    setMatrixData,
    setOrders,
    setWholeData,
    setWithoutChoice,
  } = useMatrix();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/start");
  };

  const goToAboutUs = () => {
    navigate("/");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const logoutHandler = () => {
    localStorage.clear();
    logout();
    setIsLoggedIn(false);
    setAllEntries([]);
    setAllMajors([]);
    setAllProvinces([]);

    setEntProvList([]);
    setMatrixData([]);
    setOrders([]);
    setWholeData({});
    setWithoutChoice([]);
    navigate("/");

    setFormData((prevData) => ({
      ...prevData,
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      institute: user ? user.institute : "",
      quota: "",
      quotaMartyr: "",
      sex: "",
      province: "",
    }));
  };

  return (
    <div className={styles.heading}>
      {!isLoggedIn && (
        <button className={styles.enter} onClick={goToLogin}>
          ورود{" "}
        </button>
      )}
      {isLoggedIn && (
        <button className={styles.enter} onClick={logoutHandler}>
          خروج
        </button>
      )}
      <img className={styles.logo} src={YoutabLogo} alt="logo" />
      <button className={styles.main_page} onClick={goToHome}>
        صفحه اصلی
      </button>
      <button className={styles.about_us} onClick={goToAboutUs}>
        درباره یوتاب
      </button>
    </div>
  );
};

export default Header;
