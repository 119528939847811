import React from "react";
import styles from './MatrixContent.module.css';
import { useCartChin } from '../cart-chin/CartChinContext'
import Table from "./Table";

const MatrixContent = () => {
    const { items } = useCartChin();
    const selectedItems = items.filter((item) => item.isSelected);
    return(
        <div className={styles.wrapper}>
            <div className={styles.list_of_majors}></div>
            <div className={styles.maj_pro_dur}>رشته، استان و دوره</div>
            <div className={styles.bar}>تعداد کل کدرشته های قابل انتخاب: {selectedItems.length}</div>
            <div className={styles.restriction}>شما مجاز به انتخاب حداکثر 250 کدرشته هستید.</div>
            <Table />
        </div>
    )
}

export default MatrixContent;