import React from "react";
import styles from './MapContent.module.css';
import MapImage from "./MapImage";
import MapResult from "./MapResult";
import MapInfo from "./MapInfo";

const MapContent = () => {
    return(
        <div className={styles.wrapper}>
            <MapImage />
            <MapResult />
            <MapInfo />
        </div>
    )
}

export default MapContent;