import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfoDurationModal from './MoreInfoDurationModal';

const MoreInfoDurationPortal = ({ text, isOpen, onClose }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <MoreInfoDurationModal onClose={onClose} text={text}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default MoreInfoDurationPortal;