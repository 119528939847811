import React from "react";
import styles from './WhyYoutab.module.css';
import WhyImg1 from '../images/why-youtab-1.png';
import WhyImg2 from '../images/why-youtab-2.png';
import WhyImg3 from '../images/why-youtab-3.png';
import WhyImg4 from '../images/why-youtab-4.png';
import WhyImg5 from '../images/why-youtab-5.png';
import WhyImg6 from '../images/why-youtab-6.png';

const WhyYoutab = () => {

    const whyItems = [
        {
            img: WhyImg1,
            text: 'اعمال شرایط داوطلب در فرایند انتخاب رشته'
        },
        {
            img: WhyImg2,
            text: 'افزایش دقت و تسریع در فرایند انتخاب رشته'
        },
        {
            img: WhyImg3,
            text: ' ارائه پشتیبانی 24 ساعته در بازه زمانی انتخاب رشته'
        },
        {
            img: WhyImg4,
            text: 'اتوماسیون فرایند انتخاب رشته'
        },
        {
            img: WhyImg5,
            text: 'هماهنگی مناسب میان عملگر های سایت'
        },
        {
            img: WhyImg6,
            text: ' ارائه تمامی کدرشته های دفترچه انتخاب رشته سازمان سنجش'
        },
    ]

    return(
        <div className={styles.wrapper}>
            <title className={styles.title}>چرا یوتاب؟</title>
            <ul className={styles.items}>
                {whyItems.map((item, index) => {
                    return <li key={index}>
                        <img src={item.img} alt="pic" className={styles.item_img}/>
                        <p className={styles.item_text}>{item.text}</p>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default WhyYoutab;