import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const ItemsList = ({ items, indexOfList, showChance }) => {
  const sexEnum = {
    1: "مرد",
    2: "زن",
    3: "مرد - زن",
  };

  const shiftEnum = {
    0: "اول",
    1: "دوم",
    2: "اول و دوم",
  };

  const ENTRY_ENUM = {
    0: "روزانه",
    1: "نوبت دوم",
    2: "مشترک",
    3: "غیرانتفاعی",
    4: "پیام نور",
    5: "شهريه پرداز",
    6: "پرديس خودگردان",
    7: "سیل زدگان",
    8: "داوطلبان جنوب کرمان",
    9: "مخصوص داوطلبان شهرستان بشاگرد",
    10: "صرفا سوابق تحصیلی وزارت بهداشت",
    11: "مناطق محروم",
    12: "مجازی",
    13: "تعهدی وزارت بهداشت",
    14: "تعهدی پزشکی- شورای عالی انقلاب",
    15: "آزاد تمام وقت",
    16: "خودگردان آزاد",
    17: "بورسیه دانش",
    18: "بورسیه صنعتی",
    19: "بورسیه سایپا",
    20: "بورسیه انرژی اتمی",
    21: "تربیت مدرس",
  };

  const PROVINCE_ENUM = {
    0: "تهران",
    1: "تهران",
    2: "تهران",
    3: "تهران",
    4: "تهران",
    5: "البرز",
    6: "اردبیل",
    7: "اصفهان",
    8: "ایلام",
    9: "بوشهر",
    10: "چهارمحال و بختیاری",
    11: "فارس",
    12: "گیلان",
    13: "گلستان",
    14: "همدان",
    15: "هرمزگان",
    16: "کرمان",
    17: "کرمانشاه",
    18: "خراسان جنوبی",
    19: "خراسان رضوی",
    20: "خراسان شمالی",
    21: "خوزستان",
    22: "كهگیلویه و بویراحمد",
    23: "كردستان",
    24: "لرستان",
    25: "مركزی",
    26: "مازندران",
    27: "قزوین",
    28: "قم",
    29: "سمنان",
    30: "سیستان و بلوچستان",
    31: "آذربایجان شرقی",
    32: "آذربایجان غربی",
    33: "یزد",
    34: "زنجان",
  };
  const labelColors = {
    روزانه: ["#0092A4", "#CBF2F7"],
    "نوبت دوم": ["#2E4290", "#CDDAFD"],
    غیرانتفاعی: ["#FF00C7", "#FFDBF7"],
    "پرديس خودگردان": ["#FF000F", "#FFE0E0"],
    "شهريه پرداز": ["#0043FF", "#DFE7FD"],
    "پیام نور": ["#FF7000", "#FFF1E6"],
    مشترک: ["#EFAD00", "#FFF9D8"],
    "آزاد تمام وقت": ["#8C3400", "#E2CFC4"],
    "خودگردان آزاد": ["#8C3400", "#E2CFC4"],
    "بورسیه دانش": ["#0092A4", "#CBF2F7"],
    "بورسیه انرژی اتمی": ["#0092A4", "#CBF2F7"],
    "بورسیه سایپا": ["#0092A4", "#CBF2F7"],
    "بورسیه صنعتی": ["#0092A4", "#CBF2F7"],
    "تربیت مدرس": ["#0092A4", "#CBF2F7"],
    "تعهدی پزشکی- شورای عالی انقلاب": ["#0092A4", "#CBF2F7"],
    "تعهدی وزارت بهداشت": ["#0092A4", "#CBF2F7"],
    "سیل زدگان": ["#0092A4", "#CBF2F7"],
    "صرفا سوابق تحصیلی وزارت بهداشت": ["#0092A4", "#CBF2F7"],
    "داوطلبان جنوب کرمان": ["#0092A4", "#CBF2F7"],
    "مخصوص داوطلبان شهرستان بشاگرد": ["#0092A4", "#CBF2F7"],
    "مناطق محروم": ["#0092A4", "#CBF2F7"],
    مجازی: ["#9961EC", "#ECE0FF"],
  };
  const scenarioEnum = {
    "no data": "نامشخص",
    optimistic: "خوشبینانه",
    realistic: "واقع بینانه",
    pessimistic: "بدبینانه",
  };

  const styles = StyleSheet.create({
    listItem: {
      color: "#2E4290",
      marginTop: 17,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      direction: "rtl",
      position: "relative",
      width: "100%",
      height: 25,
      paddingBottom: 5,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: 20,
    },
    text: {
      fontSize: 6,
      fontFamily: "SansIran",
      direction: "rtl",
      position: "absolute",
    },
    description: {
      fontSize: 6,
      fontFamily: "SansIran",
      direction: "rtl",
      position: "absolute",
      marginRight: 3,
      marginTop: 20,
      textAlign: "right",
      width: "94%",
    },
    text1: {
      width: "3%",
      right: "4.5%",
    },
    text2: {
      width: "5%",
      right: "11%",
    },
    text3: {
      width: "18%",
      right: "19%",
    },
    text4: {
      width: "15%",
      right: "40%",
    },
    text5: {
      width: "15%",
      right: "32%",
    },
    text6: {
      width: "9%",
      right: "71.5%",
    },
    text7: {
      width: "5%",
      right: "77%",
      textAlign: "center",
    },
    text8: {
      width: "5%",
      right: "86%",
    },
    text9: {
      width: "5%",
      right: "92%",
    },
  });

  return (
    <View>
      {items &&
        items.map((item, index) => (
          <View key={index} style={styles.listItem}>
            <View style={styles.row}>
              <Text style={[styles.text, styles.text1]}>
                {indexOfList * 15 + index + 1}
              </Text>
              <Text style={[styles.text, styles.text2]}>{item.code}</Text>
              <Text style={[styles.text, styles.text3]}>{item.name}</Text>
              <Text style={[styles.text, styles.text4]}>{item.university}</Text>
              {item.branch_code && (
                <Text style={[styles.text, styles.text5]}>
                  {item.branch_code}
                </Text>
              )}
              <Text
                style={{
                  fontSize: 6,
                  fontFamily: "SansIran",
                  direction: "rtl",
                  paddingTop: 2,
                  paddingBottom: 2,
                  position: "absolute",
                  width: "11%",
                  right: "56%",
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderColor: "#447789",
                  borderRadius: 10,
                  paddingLeft: 4,
                  paddingRight: 4,
                  backgroundColor: labelColors[ENTRY_ENUM[item.entry_type]][1],
                  color: labelColors[ENTRY_ENUM[item.entry_type]][0],
                  textAlign: "center",
                }}
              >
                {ENTRY_ENUM[item.entry_type]}
              </Text>
              <Text style={[styles.text, styles.text6]}>
                {PROVINCE_ENUM[item.province]}
              </Text>
              <Text style={[styles.text, styles.text7]}>
                {sexEnum[item.sex]}
              </Text>
              <Text style={[styles.text, styles.text8]}>
                {shiftEnum[item.shift]}
              </Text>
              {showChance && (
                <Text style={[styles.text, styles.text9]}>
                  {scenarioEnum[item.scenario]}
                </Text>
              )}
            </View>
            {item.description !== "nan" && (
              <Text style={styles.description}>توضیحات:{item.description}</Text>
            )}
          </View>
        ))}
    </View>
  );
};

export default ItemsList;
