import React, { useRef, useEffect } from "react";
import styles from './MoreInfoProvinceModal.module.css';

const MoreInfoProvinceModal = ({ onClose, province }) => {


    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    const similar_provinces = {
        "تهران": ["البرز", "قم", "قزوین", "زنجان", "سمنان", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "آذربایجان شرقی": ["آذربایجان غربی", "اردبیل", "کردستان", "کرمانشاه", "همدان"],
        "آذربایجان غربی": ["آذربایجان شرقی", "اردبیل", "کردستان", "کرمانشاه", "همدان"],
        "اردبیل": ["آذربایجان شرقی", "آذربایجان غربی", "کردستان", "کرمانشاه", "همدان"],
        "كردستان" : ["کرمانشاه", "همدان", "آذربایجان شرقی", "آذربایجان غربی", "اردبیل"],
        "کرمانشاه": ["کردستان",  "همدان", "آذربایجان شرقی", "آذربایجان غربی", "اردبیل"],
        "همدان":  ["کرمانشاه", "کردستان", "آذربایجان شرقی", "آذربایجان غربی", "اردبیل"],
        "البرز": ["تهران", "قم", "قزوین", "زنجان", "سمنان", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "قم": ["تهران", "البرز", "قزوین", "زنجان", "سمنان", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "قزوین": ["تهران", "البرز", "قم", "زنجان", "سمنان", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "زنجان": ["تهران", "البرز", "قم", "قزوین", "سمنان", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "سمنان": ["تهران", "البرز", "قم", "زنجان", "قزوین", "مرکزی", "گیلان", "مازندران", "گلستان"],
        "مركزی": ["تهران", "البرز", "قم", "زنجان", "سمنان", "قزوین", "گیلان", "مازندران", "گلستان"],
        "گیلان": ["تهران", "البرز", "قم", "زنجان", "سمنان", "مرکزی", "قزوین", "مازندران", "گلستان"],
        "مازندران": ["تهران", "البرز", "قم", "زنجان", "سمنان", "مرکزی", "قزوین", "گیلان", "گلستان"],
        "گلستان": ["تهران", "البرز", "قم", "زنجان", "سمنان", "مرکزی", "قزوین", "مازندران", "گیلان"],
        "لرستان": ["ایلام", "خوزستان", "اصفهان", "یزد", "چهارمحال و بختیاری"],
        "ایلام": ["لرستان", "خوزستان", "اصفهان", "یزد", "چهارمحال و بختیاری"],
        "خوزستان": ["لرستان", "ایلام", "اصفهان", "یزد", "چهارمحال و بختیاری"],
        "اصفهان": ["لرستان", "ایلام", "خوزستان", "یزد", "چهارمحال و بختیاری"],
        "یزد": ["لرستان", "ایلام", "خوزستان", "اصفهان", "چهارمحال و بختیاری"],
        "چهارمحال و بختیاری": ["لرستان", "ایلام", "خوزستان", "اصفهان", "یزد"],
        "فارس": ["هرمزگان", "بوشهر", "کهگیلویه و بویراحمد"],
        "هرمزگان": ["فارس", "بوشهر", "کهگیلویه و بویراحمد"],
        "بوشهر": ["فارس", "هرمزگان", "کهگیلویه و بویراحمد"],
        "كهگیلویه و بویراحمد": ["فارس", "هرمزگان", "بوشهر"],
        "کرمان": ["سیستان و بلوچستان", "خراسان رضوی", "خراسان شمالی", "خراسان جنوبی"],
        "سیستان و بلوچستان": ["کرمان", "خراسان رضوی", "خراسان شمالی", "خراسان جنوبی"],
        "خراسان رضوی": ["کرمان", "سیستان و بلوچستان", "خراسان شمالی", "خراسان جنوبی"],
        "خراسان شمالی": ["کرمان", "سیستان و بلوچستان", "خراسان رضوی", "خراسان جنوبی"],
        "خراسان جنوبی": ["کرمان", "سیستان و بلوچستان", "خراسان رضوی", "خراسان شمالی"]
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}> 
            <div className={styles.title}>:استان های مشابه {province.name}</div>
                <ul className={styles.similar_provinces}>
                    {similar_provinces[province.name].map((similarProvince, index) => (
                        <li key={index} className={styles.simi_province}>{similarProvince}</li>
                    ))}
                </ul>
            </div>
        </div>
    ); 
}

export default MoreInfoProvinceModal;