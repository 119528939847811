import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCartChin } from "./CartChinContext";
import styles from "./ControlButtonsCartchin.module.css";
import NextCartchinModal from "./NextCartchinModal";

const ControlButtonsCartchin = () => {
  const { items } = useCartChin();
  const selectedItems = items.filter((item) => item.isSelected);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const nextStage = () => {
    navigate("/pdf");
  };

  const preStage = () => {
    navigate("/matrix");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.previous} onClick={preStage}>
        مرحله قبلی
      </button>
      <button
        className={styles.next}
        onClick={openModal}
        disabled={selectedItems.length > 200}
      >
        چاپ لیست
      </button>
      <button className={styles.cancel}>انصراف</button>
      <div className={styles.major_codes}>
        تعداد کدرشته ها: {selectedItems.length}
      </div>
      <NextCartchinModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={nextStage}
      />
    </div>
  );
};

export default ControlButtonsCartchin;
