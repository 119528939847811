import React from "react";
import styles from './ProvincesList.module.css';
import ProvinceItem from "./ProvinceItem";
import { useProvinces } from "../ProvincesContext";
import { CiSearch } from "react-icons/ci";

const ProvincesList = () => {

    const { selectedProvinces } = useProvinces();

    return(
        selectedProvinces.length === 0 ? 
        <div className={styles.no_chosen}>
            <CiSearch className={styles.search_icon}/>
            هنوز استانی انتخاب نشده است!</div>
        :
        <ul className={styles.wrapper}>
            {selectedProvinces.map((province, index) => (
                <ProvinceItem key={index} province={province} index={index}/>
            ))}
        </ul>
    )
}

export default ProvincesList;