import React, { useState, useEffect } from "react";
import { useKonkoorContext } from './KonkoorContext';
import styles from './ThirdKonkoor.module.css';

const ThirdKonkoor = () => {
    const { firstKonkoorData, secondKonkoorData, thirdKonkoorData, setHasThirdKonkoor
        , setThirdKonkoorData, formData } = useKonkoorContext();
        const [group, setGroup] = useState('');

        const studyGroup = {};
    
        if (firstKonkoorData.study_group !== '4' && secondKonkoorData.study_group !== '4') {
            studyGroup['زبان'] = 4;
        }
        else if (firstKonkoorData.study_group !== '3' && secondKonkoorData.study_group !== '3') {
            studyGroup['هنر'] = 3;
        }
        else{
            studyGroup['ریاضی'] = 0;
            studyGroup['انسانی'] = 1;
            studyGroup['تجربی'] = 2; 
        }

    const EntryTypeExam = {
        AZMOON_ROOZ: group === '0' ? [0, 1, 2, 7, 8, 9, 11, 17, 18, 19, 20, 21] : group === '1' ?  [0, 1, 2, 7, 8, 9, 11, 17, 18, 21] : group ==='2' ? [0, 1, 2, 7, 8, 9, 11, 13, 14, 17, 18, 21] : group ==='3' ? [0,1,7,8,9,11, 21] : [0,1,2,7,8,9,11, 21],
        AZMOON_PAR: group === '0' ? [5,6] : group === '2' ? [5, 6, 15, 16] : [],
        AZMOON_PAYAM: group === '3' ? [3] : [3, 4],
        AZMOON_BEJOZ: [],
        AZMOON_BEHYARI : []
    };
    
    const EntryTypeRecord = {
        SAVABEGH_ROOZ: [0, 1, 7, 8, 10, 11, 12, 17, 18],
        SAVABEGH_PAR: [6],
        SAVABEGH_PAYAM: [3, 4],
        SAVABEGH_AZAD: []
    };

    const languages = {
        "انگلیسی": 0,
        "فرانسه": 1,
        "روسی": 2,
        "آلمانی": 3,
        "ایتالیایی": 4
    }

    const [language, setLanguage] = useState('');
    const [countryRank, setCountryRank] = useState('');
    const [regionRank, setRegionRank] = useState('');
    const [wholeScore, setWholeScore] = useState('');
    const [eligibleExam, setEligibleExam] = useState([]);
    const [eligibleRecord, setEligibleRecord] = useState([]);
    const [azmoonRooz, setAzmoonRooz] = useState('');
    const [azmoonPar, setAzmoonPar] = useState('');
    const [azmoonPayam, setAzmoonPayam] = useState('');
    const [azmoonBejoz, setAzmoonBejoz] = useState('');
    const [azmoonBehyari, setAzmoonBehyari] = useState('');
    const [savabeghRooz, setSavabeghRooz] = useState('');
    const [savabeghPar, setSavabeghPar] = useState('');
    const [savabeghPayam, setSavabeghPayam] = useState('');
    const [savabeghAzad, setSavabeghAzad] = useState('');

    const handleExamChange = (setter, enumValues) => (event) => {
        const value = event.target.value;
        setter(value);
        updateEligibleExam(value, enumValues);
    };

    const handleRecordChange = (setter, enumValues) => (event) => {
        const value = event.target.value;
        setter(value);
        updateEligibleRecord(value, enumValues);
    };

    const updateEligibleExam = (value, enumValues) => {
        setEligibleExam(prev => {
            let updated = [...prev];
            if (value === 'مجاز') {
                updated = [...new Set([...updated, ...enumValues])];
            } else if (value === 'غیرمجاز' || value === 'انتخاب کنید...') {
                updated = updated.filter(item => !enumValues.includes(item));
            }
            return updated;
        });
    };

    const updateEligibleRecord = (value, enumValues) => {
        setEligibleRecord(prev => {
            let updated = [...prev];
            if (value === 'مجاز') {
                updated = [...new Set([...updated, ...enumValues])];
            } else if (value === 'غیرمجاز' || value === 'انتخاب کنید...') {
                updated = updated.filter(item => !enumValues.includes(item));
            }
            return updated;
        });
    };
    

    const getBackgroundColor = (value) => {
        switch (value) {
            case 'مجاز':
                return '#00C34E';
            case 'غیرمجاز':
                return '#FF8D8D';
            default:
                return 'white';
        }
    };

    const handleChangeGroup = (e) => {
        setGroup(e.target.value);
    }

    const handleChangeRank = (e) => {
        setCountryRank(e.target.value);
    }

    const handleChangeRegionRank = (e) => {
        setRegionRank(e.target.value);
    }

    const handleChangeWholeScore = (e) => {
        setWholeScore(e.target.value);
    }

    const handleChangeLanguage = (e) => {
        setLanguage(e.target.value);
    }

    const handleKonkoorDelete = () => {
        setHasThirdKonkoor(false);
        setThirdKonkoorData({
            student: '',
            study_group: '',
            main_language: '',
            eligible_exam_entry: [],
            eligible_record_entry: [],
            score: '',
            rank: '',
            region_rank: ''
        });
    }

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            study_group: group
        }));
    }, [group, setThirdKonkoorData]);

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            rank: countryRank
        }));
    }, [countryRank, setThirdKonkoorData]);

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            region_rank: regionRank
        }));
    }, [regionRank, setThirdKonkoorData]);

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            score: wholeScore
        }));
    }, [wholeScore, setThirdKonkoorData]);

    useEffect(() => {
        if (group === '4') {
            setThirdKonkoorData(prevData => ({
                ...prevData,
                main_language: language
            }));
        }
    }, [language, group, setThirdKonkoorData]);

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            eligible_exam_entry: eligibleExam
        }));
    }, [eligibleExam, setThirdKonkoorData]);

    useEffect(() => {
        setThirdKonkoorData(prevData => ({
            ...prevData,
            eligible_record_entry: eligibleRecord
        }));
    }, [eligibleRecord, setThirdKonkoorData]);

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>اطلاعات کنکور سوم
            <button className={styles.delete_second} onClick={handleKonkoorDelete}>حذف کنکور سوم</button>
            </div>
            <label className={styles.field_label}>رشته کنکور سوم:</label>
            <select className={styles.field_dropdown} 
            value={group}
            onChange={(e) => handleChangeGroup(e)}
            >
                <option 
                value="انتخاب کنید"
                >انتخاب کنید</option>
                {Object.keys(studyGroup).map((key) => (
                    <option key={key}
                    value={studyGroup[key]}
                    >{key}</option>
                ))}
            </select>
            <label className={styles.country_label}>رتبه کشوری:</label>
            <input className={styles.country_text} type="text" value={countryRank}
            onChange = {(e) => handleChangeRank(e)}
            ></input>

            <label className={styles.region_label}>رتبه در منطقه:</label>
            <input className={styles.region_text} type="text" value={regionRank}
            onChange= {(e) => handleChangeRegionRank(e)}></input>

            {formData.quotaMartyr !== 'ندارم' &&
            <>
            <label className={styles.extra_rank_label}>رتبه سهمیه ایثارگران:</label>
            <input className={styles.extra_rank_text} type="text"></input>
            </>}

            {thirdKonkoorData.study_group === '4' && 
            <>
            <label className={styles.language_label}>زبان:</label>
            <select className={styles.language_dropdown} 
            value={language}
            onChange={(e) => handleChangeLanguage(e)}
            >
                <option 
                value="انتخاب کنید"
                >انتخاب کنید</option>
                {Object.keys(languages).map((key) => (
                    <option key={key}
                    value={languages[key]}
                    >{key}</option>
                ))}
            </select>
            </>}
            
            <label className={styles.whole_level_label}>تراز کل:</label>
            <input className={styles.whole_level_text} type="text" value={wholeScore}
            onChange = {(e) => handleChangeWholeScore(e)}
            ></input>
            
            <label className={styles.exam_level_label}>تراز آزمون:</label>
            <input className={styles.exam_level_text} type="text"></input>
            
            <label className={styles.record_level_label}>تراز سوابق:</label>
            <input className={styles.record_level_text} type="text"></input>

            <div className={styles.courses_with_exam_title}>دوره های مجاز با آزمون:</div>
            <div className={styles.courses_with_exam}>
                <label className={styles.roozaneh_label}>روزانه/نوبت دوم</label>
                <select 
                className={styles.roozaneh_text} 
                type="text" 
                onChange={(event) => handleExamChange(setAzmoonRooz, EntryTypeExam.AZMOON_ROOZ)(event)} 
                style = {{ backgroundColor: getBackgroundColor(azmoonRooz) }}
                value={azmoonRooz}
                >
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                <label className={styles.pardis_label}>پردیس شهریه پرداز/آزاد</label>
                <select className={styles.pardis_text} type="text"
                onChange={(event) => handleExamChange(setAzmoonPar, EntryTypeExam.AZMOON_PAR)(event)}
                style = {{ backgroundColor: getBackgroundColor(azmoonPar) }}
                value={azmoonPar}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>
                <label className={styles.payam_label}>پیام نور/غیرانتفاعی</label>
                <select className={styles.payam_text} type="text"
                onChange={(event) => handleExamChange(setAzmoonPayam, EntryTypeExam.AZMOON_PAYAM)(event)}  
                style = {{ backgroundColor: getBackgroundColor(azmoonPayam) }}
                value={azmoonPayam}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                <label className={styles.azad_label}
                style={{ borderLeft: group === '2' ? 'none' : '1px solid #000080', borderRadius: group === '2' ? '0' : '4px 0 0 0' }}
                >دانشگاه آزاد به جز چهار رشته</label>
                <select className={styles.azad_text} type="text"
                onChange={(event) => handleExamChange(setAzmoonBejoz, EntryTypeExam.AZMOON_BEJOZ)(event)} 
                style = {{ backgroundColor: getBackgroundColor(azmoonBejoz),
                    borderLeft: group === '2' ? 'none' : '1px solid #000080', borderRadius: group === '2' ? '0' : '0 0 0 4px' }}
                value={azmoonBejoz}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                {group === '2' && 
                <>
                <label className={styles.behyari_label}>بهیاری</label>
                <select className={styles.behyari_text} type="text"
                onChange={(event) => handleExamChange(setAzmoonBehyari, EntryTypeExam.AZMOON_BEHYARI)(event)} 
                style = {{ backgroundColor: getBackgroundColor(azmoonBehyari) }}
                value={azmoonBehyari}
                >
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>
                </>}
            </div>

            <div className={styles.courses_with_record_title}>دوره های مجاز با سوابق:</div>
            <div className={styles.courses_with_record}>
                <label className={styles.roozaneh2_label}>روزانه/نوبت دوم</label>
                <select className={styles.roozaneh2_text} type="text"
                onChange={(event) => handleRecordChange(setSavabeghRooz, EntryTypeRecord.SAVABEGH_ROOZ)(event)} 
                style = {{ backgroundColor: getBackgroundColor(savabeghRooz) }}
                value={savabeghRooz}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                <label className={styles.pardis2_label}>پردیس شهریه پرداز</label>
                <select className={styles.pardis2_text} type="text"
                onChange={(event) => handleRecordChange(setSavabeghPar, EntryTypeRecord.SAVABEGH_PAR)(event)} 
                style = {{ backgroundColor: getBackgroundColor(savabeghPar) }}
                value={savabeghPar}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                <label className={styles.payam2_label}>پیام نور/غیرانتفاعی</label>
                <select className={styles.payam2_text} type="text"
                onChange={(event) => handleRecordChange(setSavabeghPayam, EntryTypeRecord.SAVABEGH_PAYAM)(event)} 
                style = {{ backgroundColor: getBackgroundColor(savabeghPayam) }}
                value={savabeghPayam}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>

                <label className={styles.azad2_label}>آزاد</label>
                <select className={styles.azad2_text} type="text"
                onChange={(event) => handleRecordChange(setSavabeghAzad, EntryTypeRecord.SAVABEGH_AZAD)(event)} 
                style = {{ backgroundColor: getBackgroundColor(savabeghAzad) }}
                value={savabeghAzad}>
                    <option>انتخاب کنید...</option>
                    <option style={{ color: '#00C34E'}}>مجاز</option>
                    <option style={{ color: '#DD7474'}}>غیرمجاز</option>
                </select>
            </div>
        </div>
    )
}

export default ThirdKonkoor;