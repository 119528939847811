import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { useAuth } from "../login/AuthContext";
import styles from "./Cell.module.css";
import { useMatrix } from "./MatrixContext";
import MatrixPortal from "./MatrixPortal";

const Cell = ({ item }) => {
  const [withChoice, setWithChoice] = useState(false);
  const [responseCount, setResponseCount] = useState(0);
  const [canChoose, setCanChoose] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [optionValue, setOptionValue] = useState("انتخاب کنید");

  const {
    orders,
    setOrders,
    wholeData,
    setWholeData,
    withoutChoice,
    setWithoutChoice,
  } = useMatrix();

  const ENTRY_ENUM = {
    روزانه: 0,
    "نوبت دوم": 1,
    مشترک: 2,
    غیرانتفاعی: 3,
    "پیام نور": 4,
    "شهريه پرداز": 5,
    "پرديس خودگردان": 6,
    "سیل زدگان": 7,
    "مخصوص داوطلبان بومی شهرستانهای جنوب استان کرمان": 8,
    "مخصوص داوطلبان شهرستان بشاگرد": 9,
    "صرفا سوابق تحصیلی وزارت بهداشت": 10,
    "مناطق محروم": 11,
    مجازی: 12,
    "تعهدی وزارت بهداشت": 13,
    "تعهدی پزشکی- شورای عالی انقلاب": 14,
    "آزاد تمام وقت": 15,
    "خودگردان آزاد": 16,
    "بورسیه دانش": 17,
    "بورسیه صنعتی": 18,
    "بورسیه سایپا": 19,
    "بورسیه انرژی اتمی": 20,
    "تربیت مدرس": 21,
  };

  const PROVINCE_ENUM = {
    "تهران A": 0,
    "تهران B": 1,
    "تهران C": 2,
    "تهران D": 3,
    "تهران PA": 4,
    "تهران حومه": 0,
    "تهران مرکز": 1,
    البرز: 5,
    اردبیل: 6,
    اصفهان: 7,
    ایلام: 8,
    بوشهر: 9,
    "چهارمحال و بختیاری": 10,
    فارس: 11,
    گیلان: 12,
    گلستان: 13,
    همدان: 14,
    هرمزگان: 15,
    کرمان: 16,
    کرمانشاه: 17,
    "خراسان جنوبی": 18,
    "خراسان رضوی": 19,
    "خراسان شمالی": 20,
    خوزستان: 21,
    "كهگیلویه و بویراحمد": 22,
    كردستان: 23,
    لرستان: 24,
    مركزی: 25,
    مازندران: 26,
    قزوین: 27,
    قم: 28,
    سمنان: 29,
    "سیستان و بلوچستان": 30,
    "آذربایجان شرقی": 31,
    "آذربایجان غربی": 32,
    یزد: 33,
    زنجان: 34,
  };

  const isItemInWholeData = wholeData.hasOwnProperty(item.id);

  useEffect(() => {
    if (isItemInWholeData) {
      setWithChoice(true);
      setCanChoose(false);
      setResponseCount(wholeData[item.id].length);
    } else {
      setWithChoice(false);
      setCanChoose(true);
    }
  }, [isItemInWholeData, wholeData, item.id]);

  const transformDataToArray = (data) => {
    console.log("🚀 ~ transformDataToArray ~ data:", data);
    const result = [];
    for (const key in data["1"]) {
      const code = data["1"][0]?.code;
      const name = data["1"][0]?.name;
      const university = data["1"][0]?.university;
      result.push({
        id: `${key}-${code}-${name}-${university}`,
        ...data["1"][key],
      });
    }
    return result;
  };

  const closeModal = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (optionValue.includes("حذف")) {
      const updatedOrders = orders.map((order) => {
        if (order && order.id === item.id) {
          return { ...order, isDeleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
    } else if (optionValue === "تعیین اولویت") {
      setIsPopupOpen(true);
      setOptionValue("انتخاب کنید");
      const updatedOrders = orders.map((order) => {
        if (order && order.id === item.id) {
          return { ...order, isDeleted: false };
        }
        return order;
      });
      setOrders(updatedOrders);
    } else {
      const updatedOrders = orders.map((order) => {
        if (order && order.id === item.id) {
          return { ...order, isDeleted: false };
        }
        return order;
      });
      setOrders(updatedOrders);
    }
  }, [optionValue]);

  const { accountType } = useAuth();

  const validMajorEndpoint =
    accountType === "0" ? "valid_majors/" : "azad/valid_majors/";

  const handleButtonClick = async () => {
    const token = localStorage.getItem("token");
    const requestBody = {
      1: {
        major_name: item.majorName,
        entry_type: ENTRY_ENUM[item.entryProv.entry],
        province: PROVINCE_ENUM[item.entryProv.province],
        is_center: item.entryProv.isCenter,
      },
    };

    try {
      const response = await axios.post(
        `https://youtabteam.ir/api/${validMajorEndpoint}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      const listOfUnsortedMajors = transformDataToArray(data);
      const listOfMajors = listOfUnsortedMajors.sort((a, b) => a.rank - b.rank);

      if ("error" in data["1"] || listOfMajors.length === 0) {
        setWithoutChoice([...withoutChoice, item.id]);
        setCanChoose(false);
      } else {
        const count = Object.keys(data["1"]).length;
        setResponseCount(count);
        setWithChoice(true);
        setOrders([...orders, { id: item.id, isDeleted: false }]);
        const oldWholeData = cloneDeep(wholeData);
        oldWholeData[item.id] = listOfMajors;
        setWholeData(oldWholeData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const currentPriority = orders.findIndex((order) => order.id === item.id) + 1;

  return (
    <td className={styles.cell}>
      {withoutChoice && withoutChoice.indexOf(item.id) !== -1 ? (
        <button className={styles.cant_choose}>غیرقابل انتخاب</button>
      ) : canChoose ? (
        <button className={styles.cell_button} onClick={handleButtonClick}>
          انتخاب کنید
        </button>
      ) : (
        <>
          {withChoice && (
            <select
              className={styles.dropdown}
              value={optionValue}
              onChange={(e) => setOptionValue(e.target.value)}
              style={{
                backgroundColor:
                  orders[currentPriority - 1] &&
                  orders[currentPriority - 1].isDeleted &&
                  "#DD7474",
              }}
            >
              <option>اولویت {currentPriority}</option>
              <option style={{ color: "#2F428F" }}>تعیین اولویت</option>
              <option style={{ color: "#DD7474" }}>
                حذف {currentPriority}
              </option>
            </select>
          )}
          <MatrixPortal
            isOpen={isPopupOpen}
            onClose={closeModal}
            itemId={item.id}
          />
          {withChoice && (
            <div
              className={styles.majors}
              style={{
                backgroundColor:
                  orders[currentPriority - 1] &&
                  orders[currentPriority - 1].isDeleted
                    ? "#DD7474"
                    : "#00A47B",
              }}
            >
              {responseCount}
            </div>
          )}
          {(!withChoice ||
            (withoutChoice && withoutChoice.indexOf(item.id) !== -1)) && (
            <button className={styles.cant_choose}>غیرقابل انتخاب</button>
          )}
        </>
      )}
    </td>
  );
};

export default Cell;
