import axios from "axios";
import React, { useState } from "react";
import { MdMoreHoriz } from "react-icons/md";
import { PiInfoLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { useMajors } from "../MajorsContext";
import styles from "./Major.module.css";
import MoreInfoMajorPortal from "./MoreInfoMajorPortal";
import MoreInfoUnitPortal from "./MoreInfoUnitPortal";

const Major = ({ major, index }) => {
  const { removeMajor } = useMajors();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [similarMajors, setSimilarMajors] = useState([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [units, setUnits] = useState([]);

  const closeUnitModal = () => {
    setIsUnitModalOpen(false);
  };

  const token = localStorage.getItem("token");
  const openModal = async () => {
    try {
      const response = await axios.get(
        `https://youtabteam.ir/api/similar_majors/${major.name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSimilarMajors(response.data.similar_majors);
    } catch (error) {
      console.error("There was an error fetching the similar majors!", error);
    } finally {
      setIsModalOpen(true);
    }
  };

  const openUnitModal = async () => {
    try {
      const response = await axios.get(
        `https://youtabteam.ir/api/university_units/${major.name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUnits(response.data.units);
    } catch (error) {
      console.error("There was an error fetching the units!", error);
      setUnits([]);
    } finally {
      setIsUnitModalOpen(true);
    }
  };

  const handleDelete = () => {
    removeMajor(major.id);
  };

  return (
    <li className={styles.major}>
      {`${index + 1}. ${major.name}`}
      <RxCross2 className={styles.delete} onClick={handleDelete} />
      <MdMoreHoriz className={styles.more} onClick={openModal} />
      <PiInfoLight className={styles.unit} onClick={openUnitModal} />
      <MoreInfoMajorPortal
        isOpen={isModalOpen}
        onClose={closeModal}
        similarMajors={similarMajors}
        major={major}
      />
      <MoreInfoUnitPortal
        isOpen={isUnitModalOpen}
        onClose={closeUnitModal}
        units={units}
        major={major}
      />
    </li>
  );
};

export default Major;
