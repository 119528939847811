import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Header from "../home/header/Header";
import Headline from "../konkoor_info/Headline";
import { useHeadline } from "../konkoor_info/HeadlineContext";
import styles from "./CartChin.module.css";
import { useCartChin } from "./CartChinContext";
import CartItem from "./CartItem";
import ControlButtonsCartchin from "./ControlButtonsCartchin";

const CartChin = () => {
  const {
    items,
    setItems,
    handlePriorityChange,
    handleDragStart,
    handleDragOver,
    handleDrop,
  } = useCartChin();
  const {
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
  } = useHeadline();

  const [showChance, setShowChance] = useState(() => {
    const savedShowChance = localStorage.getItem("showChance");
    return savedShowChance !== null ? JSON.parse(savedShowChance) : false;
  });

  useEffect(() => {
    setFirstLevelDone(true);
    setSecondLevelDone(true);
    setThirdLevelDone(true);
    setFourthLevelDone(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get("https://youtabteam.ir/api/konkoor/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const konkoorData = response.data;

        if (konkoorData.length >= 1) {
          localStorage.setItem(
            "firstKonkoorData",
            JSON.stringify(konkoorData[0])
          );
        } else {
          localStorage.setItem("firstKonkoorData", null);
        }

        if (konkoorData.length >= 2) {
          localStorage.setItem(
            "secondKonkoorData",
            JSON.stringify(konkoorData[1])
          );
        } else {
          localStorage.setItem("secondKonkoorData", null);
        }

        if (konkoorData.length >= 3) {
          localStorage.setItem(
            "thirdKonkoorData",
            JSON.stringify(konkoorData[2])
          );
        } else {
          localStorage.setItem("thirdKonkoorData", null);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("showChance", JSON.stringify(showChance));
  }, [showChance]);

  const optimistic_items = items.filter(
    (item) => item.scenario === "optimistic" && item.isSelected
  );
  const realistic_items = items.filter(
    (item) => item.scenario === "realistic" && item.isSelected
  );
  const pessimistic_items = items.filter(
    (item) => item.scenario === "pessimistic" && item.isSelected
  );
  const unknown_items = items.filter(
    (item) => item.scenario === "no data" && item.isSelected
  );

  return (
    <>
      <div className={styles.wrapper}>
        <Header />
        <Headline title="یولیست" />
        <div className={styles.content}>
          <div className={styles.restriction}>
            شما مجاز به چاپ حداکثر 200 کدرشته هستید.
          </div>
          <div className={styles.chance}>
            <div className={styles.chance_text}>نمایش احتمال قبولی</div>
            <div
              className={styles.switch_container}
              style={{ backgroundColor: showChance ? "#00A47B" : "#B0BEC5" }}
              onClick={() => setShowChance((prevState) => !prevState)}
            >
              <div
                className={styles.switch_circle}
                style={{ left: showChance ? ".3vw" : "1.7vw" }}
              />
            </div>
            {showChance && (
              <div className={styles.showChanceContainer}>
                <div className={styles.helper_container}>
                  <div
                    className={`${styles.helper_circle} ${styles.optimistic_circle}`}
                  />
                  <div className={styles.helper_text}>
                    خوشبینانه ({optimistic_items.length})
                  </div>
                </div>

                <div className={styles.helper_container}>
                  <div
                    className={`${styles.helper_circle} ${styles.realistic_circle}`}
                  />
                  <div className={styles.helper_text}>
                    واقع‌بینانه ({realistic_items.length})
                  </div>
                </div>

                <div className={styles.helper_container}>
                  <div
                    className={`${styles.helper_circle} ${styles.pessimistic_circle}`}
                  />
                  <div className={styles.helper_text}>
                    بدبینانه ({pessimistic_items.length})
                  </div>
                </div>

                <div className={styles.helper_container}>
                  <div
                    className={`${styles.helper_circle} ${styles.unknown_circle}`}
                  />
                  <div className={styles.helper_text}>
                    ({unknown_items.length}) نامشخص
                  </div>
                </div>
              </div>
            )}
          </div>
          {items.length === 0 ? (
            <div className={styles.no_chosen}>
              <CiSearch className={styles.search_icon} />
              کد رشته ای برای نمایش موجود نیست!
            </div>
          ) : (
            <ul className={styles.items}>
              {items.map((item, index) => (
                <CartItem
                  key={`${item.id}-${item.code}-${item.name}-${item.university}`}
                  item={item}
                  setItems={setItems}
                  index={index}
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onPriorityChange={handlePriorityChange}
                  numOfItems={items.length}
                  showChance={showChance}
                />
              ))}
            </ul>
          )}
        </div>
        <ControlButtonsCartchin />
      </div>
    </>
  );
};

export default CartChin;
