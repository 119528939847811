import React, { useRef, useEffect, useState } from "react";
import styles from './SelectProvinceModal.module.css';
import { CiSearch } from "react-icons/ci";
import { BsChevronExpand } from "react-icons/bs";
import { useProvinces } from "../ProvincesContext";

const SelectProvinceModal = ({ onClose }) => {

    const { allProvinces, updateProvinceSelection, addSelectedProvinces } = useProvinces();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProvinces, setFilteredProvinces] = useState(allProvinces);

    let lengthOfProvinces = allProvinces.length;
    
    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        const filtered = allProvinces.filter(province =>
            province.name.includes(searchQuery)
        );
        setFilteredProvinces(filtered);
    }, [searchQuery, allProvinces]);

    const handleCheckboxChange = (id) => {
        const updatedProvinces = filteredProvinces.map(province =>
            province.id === id ? { ...province, selected: !province.selected } : province
        );
        setFilteredProvinces(updatedProvinces);
        updateProvinceSelection(id, !filteredProvinces.find(province => province.id === id).selected);
    };

    const addProvinces = () => {
        addSelectedProvinces();
        onClose();
    }

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}>
                <title className={styles.title}>اضافه کردن استان جدید</title>
                <input
                className={styles.search_box}
                type="text"
                placeholder='جستجوی استان ها'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}/>
                <CiSearch className={styles.search_icon}/>
                <div className={styles.provinces_list}>
                    <div className={styles.province_name}>نام استان
                        <BsChevronExpand className={styles.province_icon}/>
                    </div>
                    <ul className={styles.list} 
                    style={{ width:  lengthOfProvinces >8 ? '105%' : '100%'}}
                    >
                        {filteredProvinces.map((item, index) => (
                            <li key={index}
                            className={styles.province}>
                                <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        <label>{item.name}</label>
                    </li>
                ))}
                    </ul>
                </div>
                <button className={styles.add} onClick={addProvinces}>اضافه کردن</button>
                <button className={styles.cancel} onClick={onClose}>انصراف</button>
            </div>
        </div>
    );
}

export default SelectProvinceModal;