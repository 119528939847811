import axios from "axios";
import React, { useEffect } from "react";
import Header from "../home/header/Header";
import Headline from "../konkoor_info/Headline";
import { useHeadline } from "../konkoor_info/HeadlineContext";
import ControlButtonsMap from "./ControlButtonsMap";
import styles from "./Map.module.css";
import MapContent from "./MapContent";
import { useMap } from "./MapContext";

const Map = () => {
  const { setData, setSelectedProvince } = useMap();
  const {
    setFirstLevelDone,
    setSecondLevelDone,
    setThirdLevelDone,
    setFourthLevelDone,
  } = useHeadline();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://youtabteam.ir/api/expected_majors/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    setSelectedProvince(0);
    setFirstLevelDone(true);
    setSecondLevelDone(false);
    setThirdLevelDone(false);
    setFourthLevelDone(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header />
      <Headline title="یومپ" />
      <MapContent />
      <ControlButtonsMap />
    </div>
  );
};

export default Map;
