import React, { useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";
import styles from "./PDF.module.css";

const PDF = () => {
  const [items, setItems] = useState([]);
  const [fullName, setFullName] = useState("");
  const [institute, setInstitute] = useState("");
  const [quota, setQuota] = useState("");
  const [showChance, setShowChance] = useState(false);
  const [konkoorData, setKonkoorData] = useState({
    first: null,
    second: null,
    third: null,
  });

  useEffect(() => {
    const storedQuota = localStorage.getItem("quota");
    if (storedQuota !== null) {
      setQuota(storedQuota);
    }
  }, []);

  useEffect(() => {
    const storedFullName = localStorage.getItem("fullName");
    const storedInstitute = localStorage.getItem("institute");

    if (storedFullName) setFullName(storedFullName);
    if (storedInstitute) setInstitute(storedInstitute);
  }, []);

  useEffect(() => {
    const storedItems = localStorage.getItem("cartChinItems");
    if (storedItems) {
      setItems(JSON.parse(storedItems));
    }
  }, []);

  useEffect(() => {
    const firstKonkoorData =
      JSON.parse(localStorage.getItem("firstKonkoorData")) || {};
    const secondKonkoorData =
      JSON.parse(localStorage.getItem("secondKonkoorData")) || {};
    const thirdKonkoorData =
      JSON.parse(localStorage.getItem("thirdKonkoorData")) || {};

    setKonkoorData({
      first: firstKonkoorData,
      second: secondKonkoorData,
      third: thirdKonkoorData,
    });
  }, []);

  useEffect(() => {
    const storedShowChance = localStorage.getItem("showChance");
    if (storedShowChance !== null) {
      setShowChance(JSON.parse(storedShowChance));
    }
  }, []);

  const checkedItems = items.filter((item) => item.isSelected);

  return (
    <PDFViewer className={styles.page}>
      <MyDocument
        items={checkedItems}
        institute={institute}
        fullName={fullName}
        quota={quota}
        konkoorData={konkoorData}
        showChance={showChance}
      />
    </PDFViewer>
  );
};
export default PDF;
