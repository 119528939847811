import React, { useRef, useEffect, useState } from "react";
import styles from './ChangePriorityModal.module.css';

const ChangePriorityModal = ({ onClose, itemId, onPriorityChange, currentIndex, numOfItems }) => {
    const [newPriority, setNewPriority] = useState(1);
    const modalRef = useRef(null);

    const numbersArray = [...Array(numOfItems)].map((_, index) => index + 1);


    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const handleChangesDone = () => {
            onPriorityChange(itemId, newPriority);
            onClose();
    };

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}>
                <div className={styles.title}>تغییر اولویت رشته</div>
                <label className={styles.current_label}>اولویت فعلی:</label>
                <input className={styles.current_priority} readOnly value={currentIndex + 1}/>
                <label className={styles.new_label}>اولویت جدید:</label>
                <select className={styles.new_priority} onChange={(e) => setNewPriority(e.target.value)}>
                    {numbersArray.map(number => (
                        <option key={number} value={number}>{number}</option>
                    ))}
                </select>
                <button className={styles.changes_done} onClick={handleChangesDone}>انجام تغییرات</button>
                <button onClick={onClose} className={styles.cancel}>انصراف</button>
            </div>
        </div>
    );
}

export default ChangePriorityModal;
