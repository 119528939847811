import React from "react";
import styles from './Goals.module.css';
import GoalsImg from '../images/goals-img.png';

const Goals = () => {

    const goalItems = [
        "تبدیل سریع اولویت بندی داوطلب به انتخاب رشته اصولی",
        "ارائه نکاتی کاربردی در رابطه با رشته های دانشگاهی و دوره ها",
        "بررسی دقیق شرایط هر داوطلب و ارائه خدمات بر اساس آن",
        "بررسی شانس قبولی رشته های مختلف در استانهای مختلف به صورت خوش بینانه، واقع بینانه و بدبینانه",
        "اعمال رتبه بندی دانشگاه ها در رشته های مشابه"
    ]
    return(
        <div className={styles.wrapper}>
            <img className={styles.goals_img} src={GoalsImg} alt="pic"/>
            <div className={styles.goal_title}>اهداف یوتاب</div>
            <div className={styles.goal_text}>در این راه برای رسیدن
             به اهداف مجموعه که دستیابی به فرایندی است که
    در آن، انتخاب رشته به راحت ترین و صریح ترین روند خود به دور از هرگونه خطا شکل می گیرد تا تمامی شانس قبولی داوطلبان به درستی بررسی شود، از دانش روز دنیا و دانشجویان برترین دانشگاه های کشور استفاده شده است.
    </div>

        <ul className={styles.goals_list}>
            {goalItems.map((goal, index)=> {
                return <li 
                className={styles.goal_item}
                key={index}>{goal}
                </li>
            })}
        </ul>
        </div>
    )
}

export default Goals;