import React, { useState, useEffect } from "react";
import styles from './Home.module.css';
import Header from "./header/Header";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const goToCourseSelection = () => {
        if(isLoggedIn){
            navigate('/course-selection');
        }
        else{
            setLoginError(true);
        }
    };

    return(
        <div className={styles.wrapper}>
            <Header />
            <div className={styles.content}>
                <div className={styles.title}>انتخاب رشته</div>
                <div className={styles.start_text}>هم اکنون می توانید فرایند انتخاب رشته را شروع کنید.</div>
                <button onClick={goToCourseSelection} className={styles.start_button}>شروع انتخاب رشته</button>
                {loginError && <p className={styles.errorLogin}>.لطفا ابتدا وارد سایت شوید</p>}
            </div>
        </div>
    )
}

export default Home;