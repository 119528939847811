import React from 'react';

const DraggableMajor = ({ major, index, moveMajor }) => {
    const dragStart = (e, index) => {
        e.dataTransfer.setData('majorIndex', index);
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const drop = (e, index) => {
        const fromIndex = e.dataTransfer.getData('majorIndex');
        moveMajor(Number(fromIndex), index);
    };

    return (
        <div
            draggable
            onDragStart={(e) => dragStart(e, index)}
            onDragOver={(e) => dragOver(e)}
            onDrop={(e) => drop(e, index)}
        >
            {major.name}
        </div>
    );
};

export default DraggableMajor;
