import React from 'react';
import ReactDOM from 'react-dom';
import SelectProvinceModal from './SelectProvinceModal';

const SelectProvincePortal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <SelectProvinceModal onClose={onClose}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default SelectProvincePortal;