import './App.module.css';
import AppRoutes from './AppRoutes';
import { MajorsProvider } from './components/majors_and_provinces/MajorsContext';
import { ProvincesProvider } from './components/majors_and_provinces/ProvincesContext';
import { AuthProvider } from './components/login/AuthContext';
import { HeadlineProvider } from './components/konkoor_info/HeadlineContext';
import { CartChinProvider } from './components/cart-chin/CartChinContext';
import { KonkoorProvider } from './components/konkoor_info/KonkoorContext';
import { MatrixProvider } from './components/matrix/MatrixContext';
import { MapProvider } from './components/map/MapContext';

function App() {
  return (
    <AuthProvider>
      <MatrixProvider>
      <KonkoorProvider>
        <CartChinProvider>
          <HeadlineProvider>
              <ProvincesProvider>
                <MajorsProvider>
                  <MapProvider>
                  <AppRoutes />
                  </MapProvider>
                </MajorsProvider>
              </ProvincesProvider>
          </HeadlineProvider>
        </CartChinProvider>
    </KonkoorProvider>
    </MatrixProvider>
    </AuthProvider>
  );
}

export default App;
