import React, { useEffect } from "react";
import styles from './StudentInfo.module.css';
import { useAuth } from "../login/AuthContext";
import { useKonkoorContext } from "./KonkoorContext";

const StudentInfo = () => {

    const { user } = useAuth();
    const { formData, setFormData } = useKonkoorContext();

    useEffect(() => {
        if (user) {
            const fullName = `${user.first_name} ${user.last_name}`;
            localStorage.setItem('fullName', fullName);
            localStorage.setItem('institute', user.institute);
        }
    }, [user]);

    const provinces = {
    "تهران": 0,
    "البرز": 5,
    "اردبیل": 6,
    "اصفهان": 7,
    "ایلام": 8,
    "بوشهر": 9,
    "چهارمحال و بختیاری": 10,
    "فارس": 11,
    "گیلان": 12,
    "گلستان": 13,
    "همدان": 14,
    "هرمزگان": 15,
    "کرمان": 16,
    "کرمانشاه": 17,
    "خراسان جنوبی": 18,
    "خراسان رضوی": 19,
    "خراسان شمالی": 20,
    "خوزستان": 21,
    "كهگیلویه و بویراحمد": 22,
    "كردستان": 23,
    "لرستان": 24,
    "مركزی": 25,
    "مازندران": 26,
    "قزوین": 27,
    "قم": 28,
    "سمنان": 29,
    "سیستان و بلوچستان": 30,
    "آذربایجان شرقی": 31,
    "آذربایجان غربی": 32,
    "یزد": 33,
    "زنجان": 34
    }

    const sex ={
        "مرد": 1,
        "زن": 2
    }

    const quotaRegion = {
        "منطقه 1": 0,
        "منطقه 2": 1,
        "منطقه 3": 2
    }

    const quotaMartyrs = {
        "5%": 3,
        "25%": 4
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>مشخصات دانش آموز</div>
            <label className={styles.institute_label}>نام موسسه/مشاور:</label>
            <input
            className={styles.institute_text}
            type="text"
            readOnly
            value={user && user.institute ? user.institute : ''}
            />
            <label className={styles.name_label}>نام و نام خانوادگی دانش آموز:</label>
            <input
            className={styles.name_text}
            type="text"
            readOnly
            value={user && user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : ''}
            />
            <label className={styles.local_label}>استان بومی:</label>
            <select className={styles.local_dropdown} name="province"
            value={formData.province || ''}
            onChange={handleChange}>
                <option 
                value="انتخاب کنید"
                >انتخاب کنید</option>
                {Object.keys(provinces).map((key) => (
                    <option key={key}
                    value={provinces[key]}
                    >{key}</option>
                ))}
            </select>
            <label className={styles.region_label}>سهمیه مناطق:</label>
            <select className={styles.region_dropdown} name="quota"
            value={formData.quota || ''}
            onChange={handleChange}>
            <option 
                value="انتخاب کنید"
                >انتخاب کنید</option>
                {Object.keys(quotaRegion).map((key) => (
                    <option key={key}
                    value={quotaRegion[key]}
                    >{key}</option>
                ))}
            </select>
            <label className={styles.extra_label}>سهمیه ایثارگران:</label>
            <select className={styles.extra_dropdown} name="quotaMartyr"
            value={formData.quotaMartyr || ''}
            onChange={handleChange}>
            <option value="انتخاب کنید">انتخاب کنید</option>
            <option value="ندارم">ندارم</option>
                {Object.keys(quotaMartyrs).map((key) => (
                    <option key={key}
                    value={quotaMartyrs[key]}
                    >{key}</option>
                ))}
            </select>
            <label className={styles.gender_label}>جنسیت:</label>
            <select className={styles.gender_dropdown} type="text" name="sex"
            value={formData.sex || ''}
            onChange={handleChange}>
            <option 
                value="انتخاب کنید"
                >انتخاب کنید</option>
                {Object.keys(sex).map((key) => (
                    <option key={key}
                    value={sex[key]}
                    >{key}</option>
                ))}
            </select>
        </div>
    )
}

export default StudentInfo;