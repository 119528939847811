import React, { createContext, useContext, useState } from 'react';

const HeadlineContext = createContext();

export const HeadlineProvider = ({ children }) => {
    const [firstLevelDone, setFirstLevelDone] = useState(true)
    const [secondLevelDone, setSecondLevelDone] = useState(true)
    const [thirdLevelDone, setThirdLevelDone] = useState(true)
    const [fourthLevelDone, setFourthLevelDone] = useState(true)
    const [fifthLevelDone, setFifthLevelDone] = useState(false)

    return (
        <HeadlineContext.Provider value={{ 
            firstLevelDone,
            setFirstLevelDone,
            secondLevelDone,
            setSecondLevelDone,
            thirdLevelDone,
            setThirdLevelDone,
            fourthLevelDone,
            setFourthLevelDone,
            fifthLevelDone,
            setFifthLevelDone
        }}>
            {children}
        </HeadlineContext.Provider>
    );
};

export const useHeadline = () => {
    return useContext(HeadlineContext);
};
