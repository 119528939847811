import React from "react";
import styles from './Duration.module.css';
import { BsChevronExpand } from "react-icons/bs";
import DurationItem from './DurationItem';
import { useMajors } from "../MajorsContext";

const Duration = () => {
    const { allEntries, updateEntrySelection } = useMajors();
    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>تعیین اولویت های دوره تحصیلی</div>
            <div className={styles.content}>
                    <div className={styles.duration_info}>
                        <div className={styles.duration_name}>نام دوره
                            <BsChevronExpand />
                        </div>
                        <div className={styles.details}>توضیحات</div>
                    </div>
                    <ul className={styles.list}>
                    {allEntries.map((item) => (
                        <DurationItem
                            key={item.id}
                            item={item}
                            handleCheckboxChange={updateEntrySelection}
                        />
                    ))}
                    </ul>
            </div>
        </div>
    )
}

export default Duration;