import React from "react";
import styles from './NextKonkoorModal.module.css';
import ReactDOM from "react-dom";

const NextKonkoorModal = ({ isOpen, onRequestClose, onConfirm }) => {

    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <p className={styles.text}>آیا از رفتن به مرحله بعدی مطمئن هستید؟</p>
                <button onClick={onConfirm} className={styles.confirmButton}>بله</button>
                <button onClick={onRequestClose} className={styles.cancelButton}>انصراف</button>
            </div>
        </div>,
        document.body
    );
}

export default NextKonkoorModal;