import React, { useRef, useEffect, useState } from "react";
import styles from './MatrixModal.module.css';
import { useMatrix } from "./MatrixContext";

const MatrixModal = ({ onClose, itemId }) => {
    const [newPriority, setNewPriority] = useState(1);

    const { orders, setOrders } = useMatrix();

    const modalRef = useRef(null);


    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const currentPriority = orders.findIndex(order => order.id === itemId) + 1;

    const handlePriorityChange = (e) => {
        setNewPriority(parseInt(e.target.value));
    };

    const handleSaveChanges = () => {
        if (newPriority !== currentPriority) {
        const updatedOrders = [...orders];
        const itemIndex = updatedOrders.findIndex(order => order.id === itemId);
        const [movedItem] = updatedOrders.splice(itemIndex, 1);
        updatedOrders.splice(newPriority - 1, 0, movedItem);
        setOrders(updatedOrders);
        }
        onClose();
    };

    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal} ref={modalRef}>
                <div className={styles.title}>تغییر اولویت رشته</div>
                <label className={styles.current_label}>اولویت فعلی:</label>
                <input className={styles.current_priority} readOnly value={currentPriority}/>
                <label className={styles.new_label}>اولویت جدید:</label>
                <select 
                className={styles.new_priority}
                value={newPriority} 
                onChange={handlePriorityChange}>
                {orders.map((_, index) => (
                        <option key={index} value={index + 1}>{index + 1}</option>
                    ))}
                </select>
                <button className={styles.changes_done} onClick={handleSaveChanges}>انجام تغییرات</button>
                <button onClick={onClose} className={styles.cancel}>انصراف</button>
            </div>
        </div>
    );
}

export default MatrixModal;