import React from "react";
import styles from './MapImage.module.css';
import MapImageInfo from "./MapImageInfo";
import MapImg from '../../images/Youmap.svg';

const MapImage = () => {
    return(
        <div className={styles.wrapper}>
            <MapImageInfo />
            <img className={styles.map_img} src={MapImg} alt="map"/>
        </div>
    )
}

export default MapImage;