import ReactDOM from "react-dom";
import styles from "./NextMatrixModal.module.css";

const ConfirmSaveModal = ({ isOpen, onRequestClose, onConfirm }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <p className={styles.text}>آیا از ذخیره جدول مطمئن هستید؟</p>
        <button onClick={onConfirm} className={styles.confirmButton}>
          بله
        </button>
        <button onClick={onRequestClose} className={styles.cancelButton}>
          انصراف
        </button>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmSaveModal;
