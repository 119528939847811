import React from "react";
import styles from './AboutUs.module.css';
import Intro from "./Intro";
import Goals from "./Goals";
import WhyYoutab from "./WhyYoutab";
import Schools from "./Schools";
import Footer from "./Footer";


const AboutUs = () => {
    return(
        <div className={styles.wrapper}>
            <Intro />
            <Goals />
            <WhyYoutab />
            <Schools />
            <Footer />
        </div>
    )
}

export default AboutUs;