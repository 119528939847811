import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import youtabLogo from "../../images/youtab-logo.png";
import ItemsList from "./ItemsList";

Font.register({
  family: "SansIran",
  src: `${window.location.origin}/fonts/FontsFree-Net-ir_sans.ttf`,
});

const pageWidth = 595.28; // Width of an A4 page in points
const vw = (value) => (pageWidth * value) / 100;

const QUOTA_ENUM = {
  0: "منطقه 1",
  1: "منطقه 2",
  2: "منطقه 3",
  3: "5% سهمیه",
  4: "25% سهمیه",
  5: "سهمیه خانواده‌های شهدا و جانبازان",
  6: "سهمیه جانبازان",
  7: "سهمیه استعدادهای درخشان",
  8: "سهمیه اقلیت‌های قومی",
};

const groupEnum = {
  0: "ریاضی و فیزیک",
  1: "علوم انسانی",
  2: "علوم تجربی",
  3: "هنر",
  4: "زبان",
};

const styles = StyleSheet.create({
  page: {
    width: 30,
    height: 30,
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  container: {
    margin: 10,
    padding: 10,
  },
  title: {
    fontWeight: "900",
    fontSize: 12,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 15,
    right: 38,
  },
  username: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 60,
    right: 38,
  },
  username_text: {
    fontWeight: "900",
    fontSize: 9,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 38,
  },
  institute_name: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 60,
    right: 150,
  },
  institute_text: {
    fontWeight: "900",
    fontSize: 9,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 150,
  },
  major: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 60,
    right: 270,
  },
  major_text: {
    fontWeight: "900",
    fontSize: 9,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 270,
  },
  major_text1: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 270,
  },
  major_text2: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 84,
    right: 270,
  },
  major_text3: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 93,
    right: 270,
  },
  rank: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 60,
    right: 370,
  },
  rank_text: {
    fontWeight: "900",
    fontSize: 9,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 370,
  },
  rank_text1: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 370,
  },
  rank_text2: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 84,
    right: 370,
  },
  rank_text3: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 93,
    right: 370,
  },
  quota: {
    fontWeight: "900",
    fontSize: 6,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 60,
    right: 480,
  },
  priority: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 8,
  },
  major_code: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 51,
  },
  major_name: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 101,
  },
  branch_code: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 160,
  },
  uni_name: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 226,
  },

  entry: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 346,
  },
  city: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 416,
  },
  sex: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 451,
  },
  semester: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 493,
  },
  chance: {
    fontWeight: "900",
    fontSize: 8,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 120,
    right: 536,
  },
  quota_text: {
    fontWeight: "900",
    fontSize: 9,
    color: "#2E4290",
    fontFamily: "SansIran",
    position: "absolute",
    top: 75,
    right: 480,
  },
  image: {
    position: "absolute",
    width: vw(100),
    height: "auto",
    marginLeft: vw(-1.7),
    bottom: vw(-100.6),
  },
  logo: {
    width: 70,
    height: "auto",
    marginBottom: 20,
    marginLeft: 25,
  },
  line: {
    borderBottom: "1px solid #2E4290",
    width: "100%",
    position: "absolute",
    top: 55,
  },
  line2: {
    borderBottom: "1px solid #2E4290",
    width: "100%",
    position: "absolute",
    top: 150,
  },
  list: {
    marginTop: 65,
    position: "relative",
  },
});

const chunkItems = (items, size) => {
  const chunks = [];
  for (let i = 0; i < items.length; i += size) {
    chunks.push(items.slice(i, Math.min(i + size, items.length + 1)));
  }
  return chunks;
};

const MyDocument = ({
  items,
  fullName,
  institute,
  quota,
  konkoorData,
  showChance,
}) => {
  const chunks = chunkItems(items, 15);
  const accountType = localStorage.getItem("account_type");
  return (
    <Document>
      {chunks.map((chunk, index) => (
        <Page key={index} size="A4" style={styles.page}>
          <View style={styles.container}>
            <Image style={styles.logo} src={youtabLogo} />
            <Text style={styles.title}>لیست انتخاب رشته</Text>
            {index === 0 && <Text style={styles.username}>نام داوطلب</Text>}
            {index === 0 && (
              <Text style={styles.username_text}>{fullName}</Text>
            )}
            {index === 0 && (
              <Text style={styles.institute_name}>نام موسسه/مشاور</Text>
            )}
            {index === 0 && (
              <Text style={styles.institute_text}>{institute}</Text>
            )}
            {index === 0 && <Text style={styles.major}>رشته کنکور</Text>}
            {index === 0 && (
              <Text
                style={
                  konkoorData.second.rank
                    ? styles.major_text1
                    : styles.major_text
                }
              >
                {konkoorData.first && groupEnum[konkoorData.first.study_group]}
              </Text>
            )}
            {index === 0 && konkoorData.second.rank && (
              <Text style={styles.major_text2}>
                {groupEnum[konkoorData.second.study_group]}
              </Text>
            )}
            {index === 0 && konkoorData.third.rank && (
              <Text style={styles.major_text3}>
                {groupEnum[konkoorData.third.study_group]}
              </Text>
            )}
            {index === 0 && <Text style={styles.rank}>رتبه سهمیه</Text>}
            {index === 0 && (
              <Text
                style={
                  konkoorData.second.rank ? styles.rank_text1 : styles.rank_text
                }
              >
                {konkoorData.first.region_rank && konkoorData.first.region_rank}
              </Text>
            )}
            {index === 0 && konkoorData.second.rank && (
              <Text style={styles.rank_text2}>
                {konkoorData.second.region_rank}
              </Text>
            )}
            {index === 0 && konkoorData.third.rank && (
              <Text style={styles.rank_text3}>
                {konkoorData.third.region_rank}
              </Text>
            )}
            {index === 0 && <Text style={styles.quota}>نوع سهمیه</Text>}
            {index === 0 && (
              <Text style={styles.quota_text}>{QUOTA_ENUM[quota]}</Text>
            )}

            <Text style={styles.priority}>اولویت</Text>
            <Text style={styles.major_code}>کدرشته</Text>
            <Text style={styles.major_name}>نام رشته</Text>
            <Text style={styles.uni_name}>نام دانشگاه</Text>
            {accountType === "1" && (
              <Text style={styles.branch_code}>کد دانشگاه</Text>
            )}
            <Text style={styles.entry}>دوره</Text>
            <Text style={styles.city}>شهر</Text>
            <Text style={styles.sex}>جنسیت</Text>
            <Text style={styles.semester}>نیمسال</Text>
            {showChance && <Text style={styles.chance}>شانس</Text>}
          </View>
          <View style={styles.line} />
          <View style={styles.line2} />
          <View style={styles.list}>
            <ItemsList
              items={chunk}
              indexOfList={index}
              showChance={showChance}
            />
          </View>
          <View style={styles.container}></View>
        </Page>
      ))}
    </Document>
  );
};
export default MyDocument;
