import React, { useState } from "react";
import styles from './Provinces.module.css';
import ProvincesList from "./ProvincesList";
import SelectProvincePortal from "./SelectProvincePortal";

const Provinces = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    return(
        <div className={styles.wrapper}>
            <div className={styles.title}>تعیین اولویت های استان محل تحصیل</div>
            <ProvincesList />
            <button 
            className={styles.add_province_button}
            onClick={openModal}
            >اضافه کردن استان جدید +</button>
            <SelectProvincePortal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
    )
}

export default Provinces;