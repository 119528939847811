import React from 'react';
import ReactDOM from 'react-dom';
import SelectCourseModal from './SelectCourseModal';

const SelectCoursePortal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="portal">
        <SelectCourseModal onClose={onClose}/>
        </div>,
        document.getElementById('portal-root')
    );
};

export default SelectCoursePortal;